
                  @import "@@/design-tokens/all.scss";
                
:root {
  --specification-list-rows: 3;
}
.list {
  padding: 0;
  width: 100%;
  max-width: 900px;
  display: grid;
  grid-template-columns: auto auto auto;
  list-style: none;
  margin-bottom: 8px;
  column-gap: 60px;
  @media #{$stena-medium-and-down} {
    grid-template-columns: auto auto;
  }
  @media #{$stena-small-and-down} {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: auto;
    max-height: 312px;
    margin-bottom: 0;
  }

  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.item {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  &__fade {
    opacity: 0.5;
  }

}


.button_wrapper {
  display: flex;
  justify-content: center;

  &_sticky {
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 16px;
    background-color: $white;
    display: flex;
    justify-content: center;
    //   position: sticky;
    position: fixed;
    bottom: 0;
    z-index: 2;
    transition: 100ms all ease-in;
  }
}

.show_more_button {
  all: unset;
  display: flex;
  width: calc(100% - 30px);
  height: 20px;
  margin: 0 15px;
  div {
    padding: 5px;
  }
  &:focus-visible {
    outline: auto;
  }

  position: relative;

  &__text {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &__active {
      opacity: 1;
    }

  }
}
