
                  @import "@@/design-tokens/all.scss";
                

@mixin spacings($bp) {
  .ui-#{$bp}-m { margin: calc(var(--ui-#{$bp}-m) * 4px); }
  .ui-#{$bp}-mt { margin-top: calc(var(--ui-#{$bp}-mt) * 4px); }
  .ui-#{$bp}-mr { margin-right: calc(var(--ui-#{$bp}-mr) * 4px); }
  .ui-#{$bp}-mb { margin-bottom: calc(var(--ui-#{$bp}-mb) * 4px); }
  .ui-#{$bp}-ml { margin-left: calc(var(--ui-#{$bp}-ml) * 4px); }
  .ui-#{$bp}-mx { 
    margin-left: calc(var(--ui-#{$bp}-mx) * 4px); 
    margin-right: calc(var(--ui-#{$bp}-mx) * 4px);
  }
  .ui-#{$bp}-my { 
    margin-top: calc(var(--ui-#{$bp}-my) * 4px);
    margin-bottom: calc(var(--ui-#{$bp}-my) * 4px);
  }
  .ui-#{$bp}-p { padding: calc(var(--ui-#{$bp}-p) * 4px); }
  .ui-#{$bp}-pt { padding-top: calc(var(--ui-#{$bp}-pt) * 4px); }
  .ui-#{$bp}-pr { padding-right: calc(var(--ui-#{$bp}-pr) * 4px); }
  .ui-#{$bp}-pb { padding-bottom: calc(var(--ui-#{$bp}-pb) * 4px); }
  .ui-#{$bp}-pl { padding-left: calc(var(--ui-#{$bp}-pl) * 4px); }
  .ui-#{$bp}-px {
    padding-left: calc(var(--ui-#{$bp}-px) * 4px);
    padding-right: calc(var(--ui-#{$bp}-px) * 4px);
  }
  .ui-#{$bp}-py {
    padding-top: calc(var(--ui-#{$bp}-py) * 4px);
    padding-bottom: calc(var(--ui-#{$bp}-py) * 4px);
  }
}

@include spacings(sm);
@media #{$stena-medium-and-up} {
  @include spacings(md);
}
@media #{$stena-large-and-up} {
  @include spacings(lg);
}