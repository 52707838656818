
                  @import "@@/design-tokens/all.scss";
                
.button_wrapper {
    min-width: 40px;
    a {
        background: $primary-grey;
        color: $white;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            min-width: 16px;
            min-height: 16px;
            max-width: 16px;
            max-height: 16px;
        }
        &:hover {
            background: $primary-grey-tint-01;
        }
    }
    &__hidden {
        :first-child {
            display: none;
        }
    }
}

.divider {
    height: 50%;
    border-left: 1px solid $primary-grey-tint-02;
    @media #{$stena-small-and-down} {
        transform: rotate(30deg);
        height: 12px;
        margin-top: 6px;
    }
}

.page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding-top: 4px;
    color: $complementary-charcoal;
    &__current {
        background-color: $white;
        color: $complementary-charcoal-tint-03;
        border-radius: 50%;
        &__dark {
            color: $white;
            background-color: $complementary-charcoal-tint-03;
        }
    }
}

.pagination {
    &__small {
        @media #{$stena-medium-and-up} {
            display: none;
        }
    }

    &__large {
        @media #{$stena-small-and-down} {
            display: none;
        }
    }
}