
                  @import "@@/design-tokens/all.scss";
                
.autocomplete {
    position: relative;

    &__list {
        position: absolute;
        width: 100%;
        max-width: 500px;
        background: $white;
        z-index: 100;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        padding: 14px 0;
        box-shadow: 0px 15px 40px rgba(51, 51, 51, 0.15);

        &__option {
            padding: 4px 25px 4px 14px;
            &:hover {
                @media (hover: hover) and (pointer: fine) {
                    cursor: pointer;
                    background-color: $primary-blue-tint-03;
                  }
            }

            gap: 8px;
        }

        &__type {
            white-space: nowrap;
        }
    }
}

.input__fixed {
    @media #{$stena-small-and-down} {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px 24px;
        margin: 0;
        box-shadow: 0px -2px 6px rgb(0 0 0 / 10%);
        background: $white;
        z-index: 100;
    }
}

.scroll_button {
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 99;
    left: 0;
    opacity: 0;

    &__hidden {
        z-index: -99;
    }
}