
                  @import "@@/design-tokens/all.scss";
                
.contact_container {
    display: grid;
    row-gap: 16px;
    grid-template-columns: 100%;
    color:  $primary-grey;
    max-width: 360px;
    @media #{$stena-medium-and-down} {
      max-width: 330px;
    }
    @media #{$stena-small-and-down} {
      max-width: 100%;
    }
}

.category {
    list-style: none;
    width: fit-content;
    padding: 4px 8px;
    margin: 0 4px 4px 0;
    hyphens: auto;
    > div {
      hyphens: auto;
    }
}

.map {
    overflow: hidden;
    flex: 1;
    min-height: 100%;
    width: 100%;
    max-height: 760px;
    @media #{$stena-small-and-down} {
      > div {
        min-height: 250px;
      }
       margin-bottom: 40px;
    }
}
.icon {
  transform: rotate(180deg);
  transition-duration: 0.3s;
  transition-property: transform;   

  &_open {
    transform: rotate(360deg);
  }
}

.link:hover {
  opacity: 0.9;
  text-decoration: underline;
}