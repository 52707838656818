
                  @import "@@/design-tokens/all.scss";
                
.footer {
  background-color: black;
  position: relative;
  z-index: 3;

  & .footerrow:last-child {
    margin-top: #{nth($spacings, 11)}px;
  }

  &__link {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.gradient {
  margin-top: -50px;
  position: absolute;
  content: "";
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 50px;

  left: 0;
  right: 0;
  z-index: 2;
}

.signup {
  display: flex;
  flex-direction: column;
  row-gap: 9px;

  & input[type="text"] {
    outline: none;
    padding: 12px;
    width: 100%;
    border: 0;
    height: 48px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    max-width: 300px;
    width: 100%;
  }

  & input[type="text"]:focus {
    outline: none;
  }
}

.logo {
  max-width: 180px;
  width: 80%;
  height: 100%;
}

.footer-notch {
  margin: 0 auto;
  height: 19px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-width: 1208px;
  width: calc(100% - 48px);
  transform: translateY(-1px);
  @media #{$stena-medium-and-down} {
    width: calc(100% - 24px);
  }
  @media #{$stena-small-and-down} {
    width: calc(100% - 16px);
  }
}

.footer_bottom_bar {
  background-color: #262626;
  position: relative;
  z-index: 3;
}

.icon_wrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
  svg {
    min-height: 15px;
    max-height: 15px;
  }
}

.bottom_link_wrapper {
  color: $white;
  &:last-of-type {
    .divider {
      display: none;
    }
  }
}

.divider {
  height: 80%;
  border-left: 1px solid $white;
  padding-right: 1px;
}
