
                  @import "@@/design-tokens/all.scss";
                
.hero_picture {
  position: absolute;
  top: 0;
  height: 100%;
}

.hero__large {
  @media #{$stena-medium-and-down} {
    display: none;
  }
}

.hero__small {
  @media #{$stena-large-and-up} {
    display: none;
  }
}

.herocontainer {
  --page-notch-height: 40px;
  position: relative;

  &.hero--hasnotch {
    margin-bottom: calc(var(--page-notch-height) * -1.9);
    z-index: 0;
  }

  &.hero--hasnotch::after {
    content: "";
    height: var(--page-notch-height);
    background-color: white;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 1208px;
    position: relative;
    bottom: calc(var(--page-notch-height));
    margin: 0 auto;
    width: calc(100% - 48px);
    @media #{$stena-medium-and-down} {
      width: calc(100% - 24px);
    }
    @media #{$stena-small-and-down} {
      width: calc(100% - 16px);
    }
  }

  // hide first section(fullwidth) in small screen
  @media #{$stena-medium-and-down} {
    display: none;
  }

  // Hide first sibling section(split hero) in large screen
  @media #{$stena-large-and-up} {
    + section {
      display: none;
    }
  }
}

.line {
  width: 60px;
  height: 0px;
  border: 0;
  border-bottom: 2px solid $white;
  color: $white;
  margin-top: 8px;
  margin-bottom: 16px;
}
.hero {
  .hero_wrapper {
    max-width: 1100px;
    // -40px is the size of the notch/padding
    min-height: calc(513px - 40px);
    margin: 0 auto;
    padding-left:  40px;
    padding-right: 40px;
    z-index: 100;

    .hero_content {
      max-width: 516px;
      justify-content: center;
    }

    &--left {
      justify-content: flex-start;
      text-align: left;
      .hero_content {
        align-items: flex-start;
   
        .line {
          margin-left:0;
        }
      }
      
    }
    &--center {
      justify-content: center;
      text-align: center;
      .hero_content {
        max-width: 760px;
        align-items: center;
      }
      .hero_content__br {
        .line {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &--right {
      justify-content: flex-end;
      text-align: right;
      .hero_content {
        align-items: flex-end;
      }

      .line {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  &--left {
    overflow: inherit;
  }

  &--right {
    overflow: inherit;
  }

  &--center {
    overflow: inherit;
  }

  .hero_content {
    
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__buttonwrapper {
      display: flex;
      gap: #{get-spacing(5)}px;
      @media #{$stena-small-and-down} {
        flex-direction: column;
      }

      &--left {
        justify-content: left;
        text-align: left;
      }
      &--center {
        justify-content: center;
        text-align: center;
      }

      &--right {
        justify-content: end;
        text-align: right;
      }
    }
  }
}
