
                  @import "@@/design-tokens/all.scss";
                
.category_item {
    position: relative;
}

.container {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 2px;
    color: $primary-green;
    background-color: $primary-green-tint-04;
  }
  
  .category_item input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .category_item input:checked ~ .container {
    color: $white;
    background-color: $primary-green-tint-01;
  }

  .category_item input:hover:not([disabled]) ~ .container {
    @media (hover: hover) and (pointer: fine) {
      color: $primary-green-tint-01;
    }
  }

  .category_item input:hover:checked ~ .container {
    @media (hover: hover) and (pointer: fine) {
      color: $primary-green-tint-03;
    }
  }

  .category_item input:disabled ~ .container {
    color: $primary-green-tint-02;
  }

  .category_item input:disabled {
    cursor: default;
  }

  .label {
    display: flex;
    gap: 4px;
    order: 1;
    align-items: center;
  }

  .count {
    white-space: nowrap;
  }