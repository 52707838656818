
                  @import "@@/design-tokens/all.scss";
                
$ui-text-align-values: "center", "inherit", "justify", "left", "right";


@mixin textAlign($breakpoint) {
  @each $v in $ui-text-align-values {
    .ui-ta--#{$breakpoint}-#{$v} {
      text-align: #{$v};
    }
  }
}

@include textAlign(sm);
@media #{$stena-medium-and-up} {
  @include textAlign(md);
}
@media #{$stena-large-and-up} {
  @include textAlign(lg);
}