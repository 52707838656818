
                  @import "@@/design-tokens/all.scss";
                
.scroller {
  width: 100%;
}
.scroller_item {
  width: 360px;
  align-items:stretch;
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 40px;
  justify-content: center;
  width: 100%;
 
  .scroller_item {
    width: 100%;
    max-width: 360px;
  }

  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr 1fr;
  }

  @media #{$stena-small-and-down} {
    grid-auto-rows: auto;
    grid-template-columns: 100%;
    grid-gap: 40px;
    .scroller_item {
      width: 100%;
      max-width: initial;
    }
  }
}
.simplegrid{
  display: flex;
  flex-direction: row;

  @media #{$stena-small-and-down} {
    flex-direction: column;
  }
  .scroller_item {
    width: 100%;
    max-width: inherit;
  }
}
