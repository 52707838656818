
                  @import "@@/design-tokens/all.scss";
                
.text_container {
  width: 760px;
  display: flex;
  flex-direction: column;
  padding: 0 0 56px 0;
  color: $complementary-charcoal;

  @media #{$stena-medium-and-down} {
    padding-top: 60px;
    padding-bottom: 50px;
    width: 80%;
  }
  @media #{$stena-small-and-down} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.button_container {
  width: 100%;
  max-width: 1160px;
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;

  @media #{$stena-medium-and-down} {
    width: 100%;
  }
  @media #{$stena-medium-and-down} {
    padding-right: 24px;
  }
}

.image_container {
  position: relative;

  &--round {
    width: 160px;
    border-radius: 100%;
  }

  &--logo {
    width: 250px;
  }
}

.image {
  position: absolute;
  transform: translateY(-50%);

  &--round {
    border: 7px solid $white;
    border-radius: 100%;
  }

  &--logo {
    background: $white;
    object-fit: contain;
  }
}

// .circular-image {
//   position: absolute;
//   margin-top: -103px;
//   width: 206px;

//   &__border-radius {
//     border: 7px solid $white;
//     border-radius: 100%;
//   }
// }

// .image-7-3 {
//   aspect-ratio: 7 / 2;
//   position: absolute;
//   margin-top: -30px;
//   height: 60px;

//   @media #{$stena-large-and-up} {
//     margin-top: -40px;
//     height: 80px;
//   }
// }

.image_wrapper {
  position: relative;
}

.epi-edit {
  position: absolute;
  top: 0;
  left: 0;
}