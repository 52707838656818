
                  @import "@@/design-tokens/all.scss";
                
.share_wrapper {
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: $complementary-blue-tint-04;
  max-width: 755px;
  margin-left: auto;
  margin-right: auto;
}

.link_wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: left;
  margin-right: 24px;
  white-space: nowrap;
}

.link_wrapper::after {
  content:'';
  width:100%;
  height:100%;
  position:absolute;
  right:0;
  top:0;
  background: linear-gradient(90deg, rgba(228, 244, 251, 0) 50%, $complementary-blue-tint-04 100%);
}

.icon_wrapper {
  button > div {
    position: relative;
  }
  svg {
    position: absolute;
    transition: all 300ms ease-in-out;
  }
}