
                  @import "@@/design-tokens/all.scss";
                
.branchgroup {
  color: $primary-grey;
  a {
    color: $primary-grey;
  }

  &__locality {
    all: unset;
    width: 100%;
  }

  &__item {
    min-height: 42px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: $complementary-blue;
      > div {
        color: $complementary-blue;
      }
    }
    &__border {
      border-bottom: 1px solid $complementary-beige-tint-02;
    }
  }
}

.branch_card {
  position: relative;
  border-radius: 5px;
  background: $complementary-beige-tint-04;
  color: $complementary-charcoal;
  padding: 32px;

  @media #{$stena-medium-and-down} {
    padding: 24px;
  }
  @media #{$stena-small-and-down} {
    padding: 24px 32px;
  }

  &__single {
    @media #{$stena-small-and-down} {
      padding: 24px 40px 24px 32px;
    }
  }

  &__hover {
    &:hover {
      cursor: pointer;
      background: $primary-blue-tint-03;
    }
  }
}

.reset_button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  color: $primary-grey;
  svg {
    height: 12px;
    width: 12px;
  }
}
