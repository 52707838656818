
                  @import "@@/design-tokens/all.scss";
                
.search_wrapper {
    position: relative;

    .search_cards_wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100%;
      @media #{$stena-medium-and-down} {
        padding-bottom: 100px;
        overflow-y: auto;
        overscroll-behavior: contain;
      }
    } 
  
    .search {
      position: absolute;
      right: -25px;
      top: 65px;
      width: 100vw;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      transform-origin: 100% 0;
      transform: scaleY(0);
      overflow-y: hidden;
      transition: transform 0.1s ease-in-out;
  
      @media #{$stena-medium-and-down} {
        position: fixed;
        top: 100px;
        right: 0;
        left: 0;
        background: $white;
        max-width: 100vw;
        max-height: calc(100vh - 150px);
        @media #{$stena-small-and-down} {
          max-height: calc(100vh - 100px);
         }
       }

      &__open {
        transform: scaleY(1);
      }
  
      &__content {
        background: $white;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
        margin: 8px 8px 12px;
        touch-action: none;
        
        &__header {
          display: none;
        }

        @media #{$stena-medium-and-down} {
          top: 100px;
          margin: 0;
          box-shadow: none;
          &__header {
            display: block;
          }
         }

      }

      @media #{$stena-medium-and-down} {
        z-index: -1;
        box-shadow: inset 0px 8px 6px -6px rgba(0, 0, 0, 0.08), 0px 8px 6px -6px rgba(0, 0, 0, 0.08);
       }
      
    }
  }