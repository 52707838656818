
                  @import "@@/design-tokens/all.scss";
                
.option {
  position: relative;
  white-space:  normal;
}

.container {
    display: flex;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    gap: 8px;
    padding: 10px 16px;
    &__clearbutton{
      color: $complementary-blue;
      padding-top: 20px;
    }
  }
  
  .option input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    z-index: 10;
  }

  .option input:not([disabled]) {
    cursor: pointer;
  }
  
  .icon_wrapper {
    position: relative;
    height: 15px;
    width: 15px;
    min-height: 15px;
    min-width: 15px;
    &__checkmark {
      background-color: $white;
      border: 1px solid black;
      border-radius: 4px;
    }
  }

  .checkmark_icon {
    min-height: 5px;
    height: 5px;
    inset: 0;
    margin: auto;
    position: absolute;
  }

  .crossmark_icon {
    min-height: 10px;
    height: 10px;
    width: 10px;
    min-width: 10px;
    margin-bottom: 1px;
  }
  
  .option input:hover:not(:focus):not([disabled]) ~ .container > .icon_wrapper {
    color: $primary-grey-tint-02;
    border-color: $complementary-charcoal-tint-03;
  }

  .option input:hover:not(:focus):not([disabled]) ~ .container {
    background-color: $primary-grey-tint-03;
    color: $complementary-charcoal-tint-03;
  }

  .option input:disabled ~ .container > .icon_wrapper {
    border-color: $primary-grey-tint-02;
  }

  .option input:disabled ~ .container {
    color: $primary-grey-tint-02;
    text-decoration-color: $primary-green-tint-02;
  }

  .option input:not([disabled]) ~ .container > .label > .count {
    color: $complementary-charcoal-tint-03;
  }

  .label {
    display: inline;
    font-size: 0;
    margin-top: 1px;
    line-height: 14px;
    div {
      display: inline;
      &:last-child{
        margin-left: 2px;
      }
    }
    order: 1;
  }