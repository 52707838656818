
                  @import "@@/design-tokens/all.scss";
                
:global {
  .ui-datepicker {
    color: $complementary-blue !important;
    font-family: "Stena Sans" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 19px;
    letter-spacing: 2.5px;
    z-index: 3 !important;
  }

  .ui-datepicker-header {
    background: $complementary-blue !important;
    color: $white !important;
    border-radius: 2px !important;
    font-weight: 700 !important;
  }

  .ui-state-default {
    border: 1px solid $complementary-blue !important;
    border-radius: 2px !important;
    background: $white !important;
    color: $complementary-blue !important;
  }

  .ui-state-highlight {
    background: $complementary-blue !important;
    color: $white !important;
  }

  .ui-icon {
    background-image: none !important;
  }

  .ui-datepicker-next::after,
  .ui-datepicker-prev::after {
    content: "";
    border-top: 2px solid white;
    border-right: 2px solid white;
    width: 5px;
    height: 5px;
    position: absolute;
    cursor: pointer;
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    border: 1px solid $white !important;
    cursor: pointer;
  }

  .ui-datepicker-next-hover,
  .ui-datepicker-prev-hover {
    top: 2px !important;
    background-color: rgb($complementary-blue, 0.8) !important;
  }

  .ui-datepicker-next::after {
    transform: rotate(45deg);
    top: 10px;
    right: 10px;
  }

  .ui-datepicker-prev::after {
    transform: rotate(-135deg);
    top: 10px;
    left: 10px;
  }
}
