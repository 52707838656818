$sidebar-right-column-size: 400px;
$sidebar-gap: 56px;
$sidebar-left-max-width: calc(100% - $sidebar-right-column-size - $sidebar-gap);

:global {
  .product-container {
    max-width: 1240px;
    display: grid;
    margin: 0 auto;
    grid-gap: 16px;

    @media #{$stena-large-and-up} {
      grid-template-columns: 1fr $sidebar-right-column-size;
    }

    @media #{$stena-small-and-down} {
      grid-gap: 0;
    }
  }

  .product-left {
    order: 1;
    padding: 72px 40px;

    @media #{$stena-small-and-down} {
      padding: 56px 20px;
    }
    @media #{$stena-large-and-up} {
      margin: 0;
    }
  }

  .product-right {
    order: 2;
    max-width: 400px;

    padding-right: 40px;
    padding-top: 72px;

    @media #{$stena-medium-and-down} {
      padding: 0 40px 72px;
    }

    @media #{$stena-small-and-down} {
      padding: 0 20px 56px;
    }

    @media #{$stena-large-and-up} {
      margin: 0;
    }
  }

  //career page

  .container-career {
    max-width: 1240px;
    display: grid;
    margin: 0 auto;
    grid-gap: 16px;
    grid-template-columns: 1fr $sidebar-right-column-size;
    grid-template-rows: auto;
    grid-template-areas: "left right" "bottom bottom";

    @media #{$stena-medium-and-down} {
      grid-gap: 0;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas: "left" "bottom" "right";
    }
  }

  .career-left {
    grid-area: left;
  }

  .career-right {
    grid-area: right;

    @media #{$stena-large-and-up} {
      padding-top: 72px;
    }
  }

  .career-bottom-section {
    grid-area: bottom;
  }

  .sidebar-container {
    max-width: 1240px;
    display: grid;
    margin: 0 auto;
    grid-gap: 16px;

    @media #{$stena-large-and-up} {
      grid-template-columns: 1fr $sidebar-right-column-size;
    }

    @media #{$stena-small-and-down} {
      grid-gap: 0;
    }
  }

  .sidebar-left {
    order: 2;
  }

  .sidebar-left-news {
    order: 1;
  }
  .sidebar-left > :first-child {
    padding-top: 0;
  }

  .sidebar-left,
  .sidebar-left-news {
    padding-top: 32px;
    @media #{$stena-small-and-down} {
      padding-top: 16px;
    }
    .top-section-portrait {
      .image {
        width: 50%;
        @media #{$stena-medium-and-down} {
          width: 70%;
        }
        @media #{$stena-small-and-down} {
          width: 100%;
        }
      }
    }
    .top-section-image {
      padding-top: 0;
      padding-bottom: 0;
    }

    .top-section-video {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .top-section-rte {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .highlight-section {
      margin: 40px 0;
    }

    @media #{$stena-large-and-up} {
      margin: 0;
      order: 1;
    }

    div:last-child {
      div:last-child {
        .top-section-image {
          padding-bottom: 40px;
        }
      }
    }
  }

  .sidebar-right {
    order: 1;
    max-width: 400px;
    padding-right: 40px;
    padding-top: 72px;
    @media #{$stena-medium-and-down} {
      margin: 72px 40px 0;
      padding: 0;
    }

    @media #{$stena-small-and-down} {
      margin: 56px 20px 0;
      padding: 0;
    }

    @media #{$stena-large-and-up} {
      margin: 0;
      order: 2;
    }
  }

  .sidebar-right-news {
    order: 2;
    max-width: 400px;
    padding-right: 40px;
    padding-top: 72px;
    @media #{$stena-medium-and-down} {
      margin: 32px 40px 72px;
      padding: 0;
    }

    @media #{$stena-small-and-down} {
      margin: 16px 20px 56px;
      padding: 0;
    }
  }

  //career page

  .container-career {
    max-width: 1240px;
    display: grid;
    margin: 0 auto;
    grid-gap: 16px;
    grid-template-columns: 1fr $sidebar-right-column-size;
    grid-template-rows: auto;
    grid-template-areas: "left right" "bottom bottom";

    @media #{$stena-medium-and-down} {
      grid-gap: 0;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas: "left" "bottom" "right";
    }
  }

  .career-left {
    grid-area: left;
  }

  .career-right {
    grid-area: right;

    @media #{$stena-large-and-up} {
      padding-top: 72px;
    }
  }

  .career-bottom-section {
    grid-area: bottom;
  }
}
