
                  @import "@@/design-tokens/all.scss";
                
// -----------------------------------------------------
// This file was generated, do not modify this directly.
// change the design token-file and run 'npm run generate:tokens'
// -------------------------------------------------------------------
.ui-color-black {
  color: var(--ui-color-black);
}
.ui-background-black {
  background-color: var(--ui-color-black);
}
.ui-color-white {
  color: var(--ui-color-white);
}
.ui-background-white {
  background-color: var(--ui-color-white);
}
.ui-color-primary-green {
  color: var(--ui-color-primary-green);
}
.ui-background-primary-green {
  background-color: var(--ui-color-primary-green);
}
.ui-color-primary-grey {
  color: var(--ui-color-primary-grey);
}
.ui-background-primary-grey {
  background-color: var(--ui-color-primary-grey);
}
.ui-color-primary-blue {
  color: var(--ui-color-primary-blue);
}
.ui-background-primary-blue {
  background-color: var(--ui-color-primary-blue);
}
.ui-color-primary-green-tint01 {
  color: var(--ui-color-primary-green-tint01);
}
.ui-background-primary-green-tint01 {
  background-color: var(--ui-color-primary-green-tint01);
}
.ui-color-primary-green-tint02 {
  color: var(--ui-color-primary-green-tint02);
}
.ui-background-primary-green-tint02 {
  background-color: var(--ui-color-primary-green-tint02);
}
.ui-color-primary-green-tint03 {
  color: var(--ui-color-primary-green-tint03);
}
.ui-background-primary-green-tint03 {
  background-color: var(--ui-color-primary-green-tint03);
}
.ui-color-primary-grey-tint01 {
  color: var(--ui-color-primary-grey-tint01);
}
.ui-background-primary-grey-tint01 {
  background-color: var(--ui-color-primary-grey-tint01);
}
.ui-color-primary-grey-tint02 {
  color: var(--ui-color-primary-grey-tint02);
}
.ui-background-primary-grey-tint02 {
  background-color: var(--ui-color-primary-grey-tint02);
}
.ui-color-primary-grey-tint03 {
  color: var(--ui-color-primary-grey-tint03);
}
.ui-background-primary-grey-tint03 {
  background-color: var(--ui-color-primary-grey-tint03);
}
.ui-color-primary-blue-tint01 {
  color: var(--ui-color-primary-blue-tint01);
}
.ui-background-primary-blue-tint01 {
  background-color: var(--ui-color-primary-blue-tint01);
}
.ui-color-primary-blue-tint02 {
  color: var(--ui-color-primary-blue-tint02);
}
.ui-background-primary-blue-tint02 {
  background-color: var(--ui-color-primary-blue-tint02);
}
.ui-color-primary-blue-tint03 {
  color: var(--ui-color-primary-blue-tint03);
}
.ui-background-primary-blue-tint03 {
  background-color: var(--ui-color-primary-blue-tint03);
}
.ui-color-primary-blue-tint04 {
  color: var(--ui-color-primary-blue-tint04);
}
.ui-background-primary-blue-tint04 {
  background-color: var(--ui-color-primary-blue-tint04);
}
.ui-color-complementary-orange {
  color: var(--ui-color-complementary-orange);
}
.ui-background-complementary-orange {
  background-color: var(--ui-color-complementary-orange);
}
.ui-color-complementary-beige {
  color: var(--ui-color-complementary-beige);
}
.ui-background-complementary-beige {
  background-color: var(--ui-color-complementary-beige);
}
.ui-color-complementary-charcoal {
  color: var(--ui-color-complementary-charcoal);
}
.ui-background-complementary-charcoal {
  background-color: var(--ui-color-complementary-charcoal);
}
.ui-color-complementary-blue {
  color: var(--ui-color-complementary-blue);
}
.ui-background-complementary-blue {
  background-color: var(--ui-color-complementary-blue);
}
.ui-color-complementary-orange-tint01 {
  color: var(--ui-color-complementary-orange-tint01);
}
.ui-background-complementary-orange-tint01 {
  background-color: var(--ui-color-complementary-orange-tint01);
}
.ui-color-complementary-orange-tint02 {
  color: var(--ui-color-complementary-orange-tint02);
}
.ui-background-complementary-orange-tint02 {
  background-color: var(--ui-color-complementary-orange-tint02);
}
.ui-color-complementary-orange-tint03 {
  color: var(--ui-color-complementary-orange-tint03);
}
.ui-background-complementary-orange-tint03 {
  background-color: var(--ui-color-complementary-orange-tint03);
}
.ui-color-complementary-orange-tint04 {
  color: var(--ui-color-complementary-orange-tint04);
}
.ui-background-complementary-orange-tint04 {
  background-color: var(--ui-color-complementary-orange-tint04);
}
.ui-color-complementary-beige-tint01 {
  color: var(--ui-color-complementary-beige-tint01);
}
.ui-background-complementary-beige-tint01 {
  background-color: var(--ui-color-complementary-beige-tint01);
}
.ui-color-complementary-beige-tint02 {
  color: var(--ui-color-complementary-beige-tint02);
}
.ui-background-complementary-beige-tint02 {
  background-color: var(--ui-color-complementary-beige-tint02);
}
.ui-color-complementary-beige-tint03 {
  color: var(--ui-color-complementary-beige-tint03);
}
.ui-background-complementary-beige-tint03 {
  background-color: var(--ui-color-complementary-beige-tint03);
}
.ui-color-complementary-beige-tint04 {
  color: var(--ui-color-complementary-beige-tint04);
}
.ui-background-complementary-beige-tint04 {
  background-color: var(--ui-color-complementary-beige-tint04);
}
.ui-color-complementary-charcoal-tint01 {
  color: var(--ui-color-complementary-charcoal-tint01);
}
.ui-background-complementary-charcoal-tint01 {
  background-color: var(--ui-color-complementary-charcoal-tint01);
}
.ui-color-complementary-charcoal-tint02 {
  color: var(--ui-color-complementary-charcoal-tint02);
}
.ui-background-complementary-charcoal-tint02 {
  background-color: var(--ui-color-complementary-charcoal-tint02);
}
.ui-color-complementary-charcoal-tint03 {
  color: var(--ui-color-complementary-charcoal-tint03);
}
.ui-background-complementary-charcoal-tint03 {
  background-color: var(--ui-color-complementary-charcoal-tint03);
}
.ui-color-complementary-charcoal-tint04 {
  color: var(--ui-color-complementary-charcoal-tint04);
}
.ui-background-complementary-charcoal-tint04 {
  background-color: var(--ui-color-complementary-charcoal-tint04);
}
.ui-color-complementary-blue-tint01 {
  color: var(--ui-color-complementary-blue-tint01);
}
.ui-background-complementary-blue-tint01 {
  background-color: var(--ui-color-complementary-blue-tint01);
}
.ui-color-complementary-blue-tint02 {
  color: var(--ui-color-complementary-blue-tint02);
}
.ui-background-complementary-blue-tint02 {
  background-color: var(--ui-color-complementary-blue-tint02);
}
.ui-color-complementary-blue-tint03 {
  color: var(--ui-color-complementary-blue-tint03);
}
.ui-background-complementary-blue-tint03 {
  background-color: var(--ui-color-complementary-blue-tint03);
}
.ui-color-complementary-blue-tint04 {
  color: var(--ui-color-complementary-blue-tint04);
}
.ui-background-complementary-blue-tint04 {
  background-color: var(--ui-color-complementary-blue-tint04);
}
.ui-color-shades-blue01 {
  color: var(--ui-color-shades-blue01);
}
.ui-background-shades-blue01 {
  background-color: var(--ui-color-shades-blue01);
}
.ui-color-shades-blue02 {
  color: var(--ui-color-shades-blue02);
}
.ui-background-shades-blue02 {
  background-color: var(--ui-color-shades-blue02);
}
.ui-color-shades-green01 {
  color: var(--ui-color-shades-green01);
}
.ui-background-shades-green01 {
  background-color: var(--ui-color-shades-green01);
}
.ui-color-shades-green02 {
  color: var(--ui-color-shades-green02);
}
.ui-background-shades-green02 {
  background-color: var(--ui-color-shades-green02);
}
.ui-color-shades-charcoal01 {
  color: var(--ui-color-shades-charcoal01);
}
.ui-background-shades-charcoal01 {
  background-color: var(--ui-color-shades-charcoal01);
}
.ui-color-shades-charcoal02 {
  color: var(--ui-color-shades-charcoal02);
}
.ui-background-shades-charcoal02 {
  background-color: var(--ui-color-shades-charcoal02);
}
.ui-color-shades-warning01 {
  color: var(--ui-color-shades-warning01);
}
.ui-background-shades-warning01 {
  background-color: var(--ui-color-shades-warning01);
}
.ui-color-info {
  color: var(--ui-color-info);
}
.ui-background-info {
  background-color: var(--ui-color-info);
}
.ui-color-success {
  color: var(--ui-color-success);
}
.ui-background-success {
  background-color: var(--ui-color-success);
}
.ui-color-warning {
  color: var(--ui-color-warning);
}
.ui-background-warning {
  background-color: var(--ui-color-warning);
}
.ui-color-danger {
  color: var(--ui-color-danger);
}
.ui-background-danger {
  background-color: var(--ui-color-danger);
}
.ui-color-info-tint {
  color: var(--ui-color-info-tint);
}
.ui-background-info-tint {
  background-color: var(--ui-color-info-tint);
}
.ui-color-success-tint {
  color: var(--ui-color-success-tint);
}
.ui-background-success-tint {
  background-color: var(--ui-color-success-tint);
}
.ui-color-warning-tint {
  color: var(--ui-color-warning-tint);
}
.ui-background-warning-tint {
  background-color: var(--ui-color-warning-tint);
}
.ui-color-danger-tint {
  color: var(--ui-color-danger-tint);
}
.ui-background-danger-tint {
  background-color: var(--ui-color-danger-tint);
}
.ui-color-chart01 {
  color: var(--ui-color-chart01);
}
.ui-background-chart01 {
  background-color: var(--ui-color-chart01);
}
.ui-color-chart02 {
  color: var(--ui-color-chart02);
}
.ui-background-chart02 {
  background-color: var(--ui-color-chart02);
}
.ui-color-chart03 {
  color: var(--ui-color-chart03);
}
.ui-background-chart03 {
  background-color: var(--ui-color-chart03);
}