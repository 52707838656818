
                  @import "@@/design-tokens/all.scss";
                
.container {
  width: 100%;
  display: grid;
  grid-gap: 40px;
  justify-content: center;
  grid-template-columns: repeat( auto-fit, minmax(360px, 1fr) );

  &_text {
    display: flex;
    padding: 10px;
    flex-wrap: nowrap;
    width: 100%;
  }
  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: 100%;
  }
}

.textCardContainer {
  &__scroller-item {
    height: 100%;
    width: 360px;
    @media #{$stena-medium-and-down} {
      width: 350px;
    }
    @media #{$stena-small-and-down} {
      width: 100%;
    }
  }
}