
                  @import "@@/design-tokens/all.scss";
                
.hero_wrapper {
  position: relative;
  height: 100svh;

  &__hasmenu {
    margin-top: -150px;
    @media #{$stena-medium-and-down} {
      margin-top: -100px;
    }
  }
}

.preview {
  max-height: 1300px !important;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 1;

  @media #{$stena-small-and-down} {
    top: 20px;
    left: 20px;
  }

  img {
    height: 44.44px;
    opacity: 0.85;
    @media #{$stena-small-and-down} {
      height: 40.37px;
    }
  }

  &__small {
    img {
      height: 24.4px;
      @media #{$stena-small-and-down} {
        height: 22.2px;
      }
    }
  }
}

.content {
  z-index: 1;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scroll_icon {
  all: unset;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  bottom: 44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  color: $white;
  @media #{$stena-small-and-down} {
    width: 30px;
    height: 30px;
    bottom: 60px;
    &__campaign {
      bottom: 20px;
    }
  }
}

.pause_play_icon {
  position: absolute;
  bottom: 74px;
  right: 50px;
  width: 60px;
  height: 60px;
  color: $white;

  @media #{$stena-small-and-down} {
    width: 40px;
    height: 40px;
    bottom: 60px;
    right: 20px;
  }

  &__campaign {
    bottom: 44px;
    @media #{$stena-small-and-down} {
      bottom: 20px;
    }
  }

  &__pause {
    @media #{$stena-small-and-down} {
      display: none;
    }
  }
}
