
                @import "@@/design-tokens/all.scss";
                
.quick_links_container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.quick_link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $complementary-charcoal-tint-01;
  color: $complementary-charcoal-tint-01;
  border-radius: 100px;
  background-color: $white;

  padding: 8px 24px 8px 24px;
  min-height: 40px;
  min-width: 180px;
  transition: all 0.3s ease;
  cursor: pointer;
  

  @media #{$stena-small-and-down} {
    flex-grow: 1;
  }

  &:hover {
    border-color: $complementary-charcoal-tint-02;
    color: $complementary-charcoal-tint-02;
  }

  &:active {
    border-color: $complementary-charcoal;
    color: $complementary-charcoal;
  }

  &:disabled {
    border-color: $primary-grey-tint-02;
    color: $primary-grey-tint-01;
  }

  &__grow {
    flex-grow: 1;
  }
}