
                  @import "@@/design-tokens/all.scss";
                
@keyframes loading {
    to {
      background-position-x: -20%;
    }
  }

.loading {
    background-color: $stena-color-primary-grey-tint03;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) $stena-color-primary-grey-tint03;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: loading 2s ease-in-out infinite;
}

.skeleton_image {
    &_latestnews {
      height: 380px;
      @media #{$stena-medium-and-down} {
        height: 320px;
      }
    }
    &_newsmodule {
      height: 280px;
    }
  }

  .skeleton_date {
    height: 16px;
    width: 80px;
  }
  
  .skeleton_intro {
    height: 64px;
  }

  .card {
    max-width: 760px;
    text-decoration: none;
    color: $complementary-charcoal-tint-03;
    background-color: $white;
    width: 100%;
  
    &_image {
      // height: 380px;
      img {
        height: 100%;
      }
    }
  
    h5 {
      max-width: 560px;
    }
  }