
                  @import "@@/design-tokens/all.scss";
                
.info_card {
  &_color {
    min-height: 450px;
    max-width: 360px;

    @media #{$stena-medium-and-up} {
      min-height: 350px;
    }

    @media #{$stena-large-and-up} {
      min-height: 450px;
    }
  }

  &_image {
    &__image {
      img {
        border-radius: 5px 5px 0 0;
      }
    }
  }
}

.text_content {
  flex: 1;
  background: $white;
  border-radius: 5px;
  margin: -74px 12px 0;
  padding: 24px;
  z-index: 1;
  @media #{$stena-small-and-down} {
    flex: none;
  }
}

.button_info {
  
  @media #{$stena-small-and-down} {
    width: 100%;
  }

  a,
  button {
    min-height: 54px;
  }
}
