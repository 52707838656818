@font-face {
  font-family: $snw_fontfamilyname;
  src: url("../../fonts/stenasans-regular.eot");
  src: url("../../fonts/stenasans-regular.eot") format("embedded-opentype"),
    url("../../fonts/stenasans-regular.woff2") format("woff2"),
    url("../../fonts/stenasans-regular.woff") format("woff"),
    url("../../fonts/stenasans-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $snw_fontfamilyname;
  src: url("../../fonts/stenasans-medium.eot");
  src: url("../../fonts/stenasans-medium.eot") format("embedded-opentype"),
    url("../../fonts/stenasans-medium.woff2") format("woff2"),
    url("../../fonts/stenasans-medium.woff") format("woff"),
    url("../../fonts/stenasans-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $snw_fontfamilyname;
  src: url("../../fonts/stenasans-mediumitalic.eot");
  src: url("../../fonts/stenasans-mediumitalic.eot") format("embedded-opentype"),
    url("../../fonts/stenasans-mediumitalic.woff2") format("woff2"),
    url("../../fonts/stenasans-mediumitalic.woff") format("woff"),
    url("../../fonts/stenasans-mediumitalic.ttf") format("truetype");

  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $snw_fontfamilyname;
  src: url("../../fonts/stenasans-italic.eot");
  src: url("../../fonts/stenasans-italic.eot") format("embedded-opentype"),
    url("../../fonts/stenasans-italic.woff2") format("woff2"),
    url("../../fonts/stenasans-italic.woff") format("woff"),
    url("../../fonts/stenasans-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $snw_fontfamilyname;
  src: url("../../fonts/stenasans-bold.eot");
  src: url("../../fonts/stenasans-bold.eot") format("embedded-opentype"),
    url("../../fonts/stenasans-bold.woff2") format("woff2"),
    url("../../fonts/stenasans-bold.woff") format("woff"),
    url("../../fonts/stenasans-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "StenaNumber-Medium";
  src: url("../../fonts/StenaNumber-Medium.woff2") format("woff2"),
    url("../../fonts/StenaNumber-Medium.ttf") format("truetype");
}
