
                  @import "@@/design-tokens/all.scss";
                
:global {
  .container {
    padding: 72px 40px;
    // max-width: 1920px;
    margin: 0 auto;

    @media #{$stena-small-and-down} {
      padding: 56px 20px;
    }

    &--beige {
      background: $complementary-beige-tint-03;
    }
    &--white {
      background: $white;
    }
  }

  // epi-forms
  .EPiServerForms {
    font-family: "Stena Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $primary-grey;

    a {
      color: $stena-color-info;
      text-decoration: none;

      &:hover:not(.base_button) {
        text-decoration: underline;
      }
    }

    .Form__Title {
      font-family: "Stena Sans";
      font-style: normal;
      text-transform: none;
      font-weight: 400;
      font-size: 35px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 1.2px;
      color: $complementary-charcoal;
      max-width: 760px;
      margin: 0 auto !important;
    }

    .Form__Description {
      font-family: "Stena Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.5px;
      color: $complementary-charcoal;
      margin: 32px auto 0 !important;
      max-width: 760px;
    }

    section,
    .epi-editContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 24px;
      column-gap: 32px;
      position: relative;
      margin: 56px auto 0 !important;
      padding-bottom: 72px;
      max-width: 764px;
    }

    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
    }

    .Form__Element__ValidationError {
      color: $danger;
    }

    input[type="text"],
    input[type="number"],
    input[type="url"],
    textarea,
    select {
      padding: 10px;
      border: 1px solid $primary-grey-tint-02;
      border-radius: 5px;
      color: $complementary-charcoal;
      margin: 0;
    }

    input[type="text"],
    input[type="number"],
    input[type="url"],
    select:not([multiple]) {
      max-height: 46px;
    }

    .FormSelection {
      select {
        overflow: hidden;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 10px 30px 10px 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media #{$stena-small-and-down} {
          width: 100%;
        }
        &:not([multiple]) {
          background: $white;
          background-image: url("./icons/formChevronDown.svg");
          background-repeat: no-repeat;
          background-position-x: calc(100% - 10px);
          background-position-y: 50%;
        }
        option {
          padding: 10px;
          color: $complementary-charcoal;
          width: 100%;
          &:not([disabled]) {
            cursor: pointer;
          }
        }
      }
    }

    .FormDateTime__Input,
    .FormDateTimeRange input {
      background: $white;
      background-image: url("./icons/formCalender.svg");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 10px);
      background-position-y: 50%;
      padding-right: 40px !important;
    }

    select[multiple] {
      :first-child {
        display: none;
      }
    }

    input[type="radio"],
    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      margin: 0;
    }

    textarea {
      max-height: 100%;
    }

    input[aria-invalid="true"] {
      border: 1px solid $danger;
    }

    .FormTextbox,
    .FormDateTime,
    .FormDateTimeRange,
    .FormSelection,
    .FormFileUpload,
    .FormTextbox > div[lang] {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .FormDateTimeRange {
      flex-direction: row;
      legend {
        margin-bottom: 4px;
      }
    }

    .FormDateTimeRange_Seperator {
      margin-top: 10px !important;
    }

    .FormAddressElement label {
      margin-top: 32px;
    }

    .FormTextbox--Textarea,
    .FormChoice,
    .FormFileUpload,
    .FormRecaptcha,
    .FormParagraphText {
      grid-column: span 2;
    }

    .FormAddressElement {
      display: grid;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .visually-hidden {
      opacity: 0;
      position: absolute;
    }

    .FormChoice fieldset {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      column-gap: 40px;
      max-height: 200px;
      width: fit-content;
      @media #{$stena-medium-and-down} {
        max-height: 100%;
      }
    }

    .FormChoice fieldset > div {
      height: 43px;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
    }

    .FormChoice__Label {
      margin-top: 3px;
    }

    .FormChoice__Input--Radio,
    .FormChoice__Input--Checkbox {
      cursor: pointer;
    }

    .FormChoice__Input--Radio + label,
    .FormChoice__Input--Checkbox + label {
      cursor: pointer;
      padding-left: 10px;
    }

    .FormParagraphText {
      // display: flex;
      // flex-direction: column;
      // gap: 16px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 1.5rem;
      }

      p {
        // &:not(:last-child) {
        margin-bottom: 2rem;
        // }
      }

      blockquote > p,
      blockquote,
      p > blockquote {
        font-weight: normal;
        line-height: 32px;
        letter-spacing: 1.2px;
        font-size: 27px;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 32px;
          /* or 128% */

          letter-spacing: 0.9px;
        }

        @media #{$stena-small-and-down} {
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.9px;
        }
      }

      h1 {
        font-size: 40px;
        line-height: 45px;
        letter-spacing: 1.2px;

        @media #{$stena-medium-and-down} {
          font-size: 35px;
          line-height: 42px;
        }

        @media #{$stena-small-and-down} {
          font-size: 28px;
          line-height: 32px;
          letter-spacing: 3px;
        }
      }

      h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 3px;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          /* or 115% */

          letter-spacing: 3px;
          text-transform: uppercase;
        }

        @media #{$stena-small-and-down} {
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 3px;
          text-transform: uppercase;
        }
      }

      h3 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 2px;

        @media #{$stena-medium-and-down} {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }

        @media #{$stena-small-and-down} {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
      }

      h4 {
        font-weight: normal;
        font-size: 27px;
        line-height: 32px;
        letter-spacing: 1.2px;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 32px;
          /* or 128% */

          letter-spacing: 0.9px;
        }

        @media #{$stena-small-and-down} {
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.9px;
        }
      }

      h5 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 2px;

        @media #{$stena-medium-and-down} {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }

        @media #{$stena-small-and-down} {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
      }

      a {
        color: $stena-color-info;

        &:hover:not(.base_button) {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      p {
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.5px;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          /* identical to box height, or 144% */

          letter-spacing: 0.5px;
        }

        @media #{$stena-small-and-down} {
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.5px;
        }
      }
      pre {
        font-family: inherit;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.5px;
        white-space: initial;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 30px;
          /* identical to box height, or 136% */

          letter-spacing: 0.5px;
        }

        @media #{$stena-small-and-down} {
          font-weight: normal;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 0.5px;
        }
      }
    }

    .FormParagraphText + .FormChoice {
      margin-top: -32px;
    }

    .FormFileUpload {
      display: row;
      position: relative;
    }

    .FormFileUpload__Input {
      opacity: 0;
      position: absolute;
      left: -105px;
      top: 0;
      width: 285px;
      height: 40px;
      cursor: pointer;
    }

    .FilesContainer {
      padding: 12px 0 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .FileWrapper {
      display: flex;
      gap: 12px;
      background: $primary-grey-tint-03;
      border: 1px solid $primary-grey-tint-02;
      border-radius: 5px;
      background-image: url("./icons/formFile.svg");
      background-repeat: no-repeat;
      background-position-x: calc(10px);
      background-position-y: 45%;
      padding: 10px 44px;
      :last-child {
        color: $primary-grey-tint-01;
      }
    }

    .FormResetButton,
    .FormSubmitButton,
    .FormFileUpload .Form__Element__Caption {
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      min-height: 40px;
      width: fit-content;
      text-transform: uppercase;
      font-family: "Stena Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 2.5px;
      border: 0;
      cursor: pointer;
      border-radius: 0 !important;
      padding: 0 8px;
    }

    .FormResetButton,
    .FormFileUpload .Form__Element__Caption,
    .FormSubmitButton {
      width: 180px;
      white-space: normal;
    }

    .FormFileUpload .Form__Element__Caption {
      border: 1px solid $complementary-blue;
      color: $complementary-blue;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .FormResetButton {
      position: absolute;
      bottom: 0;
      right: 190px;
      color: $complementary-blue;
      background: white;
      order: 2;

      &:focus {
        outline: none;
      }
      &:focus-visible {
        outline: auto;
      }
    }

    .FormSubmitButton {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $complementary-blue;
      color: white;

      &:focus {
        outline: none;
      }
      &:focus-visible {
        outline: auto;
      }
    }

    .FormResetButton,
    .FormSubmitButton {
      @media #{$stena-small-and-down} {
        position: static;
      }
    }

    @media #{$stena-medium-and-down} {
      section {
        grid-template-columns: 1fr;
      }
      .FormTextbox--Textarea,
      .FormChoice,
      .FormFileUpload,
      .FormRecaptcha,
      .FormParagraphText {
        grid-column: span 1;
      }
    }

    @media #{$stena-small-and-down} {
      section {
        padding-bottom: 102px;
      }

      .FormResetButton {
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .FormSubmitButton {
        bottom: 48px;
        left: 0;
        width: 100%;
      }
    }

    .FormDateTime__Input,
    .FormTextbox,
    .FormDateTime,
    .FormDateTimeRange,
    .FormSelection,
    .FormDateTimeRange__End,
    .FormDateTimeRange__Start,
    .TimePicker .TimePicker__Input,
    .TimePicker select {
      margin: 0 !important;
      width: 100% !important;
    }

    .Form__Element {
      margin-bottom: 0 !important;
      justify-content: end;
    }

    .Form__Status__Message.hide {
      display: none !important;
    }

    .Form__Status__Message {
      background: $primary-green-tint-03 !important;
      background-image: url("./icons/formSuccess.svg") !important;
      background-repeat: no-repeat !important;
      background-position-x: 50% !important;
      background-position-y: 100px !important;
      margin: 40px auto 0 !important;
      max-width: 764px !important;
      padding: 220px 40px 100px !important;
      display: flex !important;
      flex-direction: column !important;

      @import "../elements/Typography/style/typography.module.scss";
      @import "../components/Button/buttonStyles.module.scss";

      h2,
      h3,
      p,
      a,
      pre,
      blockquote,
      .base_button {
        @extend .typography;
        margin: 0 auto;
      }

      h2 {
        @extend .heading1;
      }
      h3 {
        @extend .heading3;
      }
      blockquote {
        @extend .heading4;
      }
      p {
        @extend .body;
      }
      pre {
        @extend .intro;
      }
      .base_button {
        @extend .button;
      }

      > * {
        text-align: center;
      }

      div[data-epi-content-name="Text area"],
      div[data-epi-content-name="Multiple or single choice"],
      div[data-epi-content-name="reCAPTCHA"],
      div[data-epi-content-name="Rich text (with placeholders)"] {
        grid-column: span 2;
      }
    }
  }
}

// emarketeer forms
