
                  @import "@@/design-tokens/all.scss";
                
.videoItem{
  display: block;
  border-radius: initial;
  flex-grow: 1;
  &.isScrollItem{
    border-radius: 5px;
  }
}