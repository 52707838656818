
                  @import "@@/design-tokens/all.scss";
                
//ALL CARD BLOCKS
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//PRODUCT CARDS/TEXT CARDS

.headline_container {
  width: 100%;
  text-align: center;
}
.container {
  width: 100%;
  display: grid;
  grid-gap: 40px;
  justify-content: center;

  &_2 {
    grid-template-columns: 1fr 1fr;

    @media #{$stena-medium-and-down} {
      grid-template-columns: 216px 216px;
      grid-gap: 16px;
      justify-content: center;
    }
  }

  &_3 {
    grid-template-columns: 1fr 1fr 1fr;

    @media #{$stena-medium-and-down} {
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
    }
  }

  &_text {
    display: flex;
    padding: 10px;
    flex-wrap: nowrap;
    width: 100%;
  }

  @media #{$stena-small-and-down} {
    grid-template-columns: 100%;
    grid-gap: 40px;
  }
}

.line {
  width: 100%;
  height: 0;
  border: 1px solid #3f4347;
  margin: 12px;
}

.button_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.product-card {
  &__scroller-item {
    width: 360px;
    height: 100%;
    @media #{$stena-medium-and-down} {
      width: 216px;
    }
    @media #{$stena-small-and-down} {
      width: 100%;
    }
  }
}

//SERVICE DESCRIPTION
.service_scroller_item {
  display: flex;
  flex-direction: column;
  width: 560px;

  @media #{$stena-medium-and-down} {
    width: 100%;
  }
}

.service_scroller_item_small {
  display: flex;
  flex-direction: column;
  width: 360px;

  @media #{$stena-medium-and-down} {
    min-width: 360px;
    width: 100%;
  }
  @media #{$stena-small-and-down} {
    min-width: unset;
    width: 100%;
    height: auto;
  }
}

.container_service {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  gap: 40px;

  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr;
  }

  &_small {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    justify-content: center;
    gap: 40px;

    @media #{$stena-medium-and-down} {
      display: flex;
      flex-direction: column;
    }
  }

  &__flex {
    @media #{$stena-large-and-up} {
      display: flex;
    }
  }
}

//SUBLEVEL LISTING
.sublevel_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid_sublevel {
  max-width: 1208px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: 40px;
  justify-content: center;
  justify-items: center;

  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr 1fr;

    gap: 16px;
    padding: 56px 40px 56px 40px;
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: 1fr;

    padding: 56px 24px 56px 24px;
  }
}

//info_card
.info_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 72px;
  justify-content: center;
  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
  @media #{$stena-small-and-down} {
    grid-auto-rows: auto;
    grid-template-columns: 100%;
    column-gap: 40px;
  }
}

.info_card_scroller_item {
  width: 360px;
  @media #{$stena-medium-and-down} {
    width: calc(47vw - 50px);
  }
  @media #{$stena-small-and-down} {
    width: 100%;
  }
}
