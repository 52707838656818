
                  @import "@@/design-tokens/all.scss";
                
.hbd-scroller {
  &__item {
    display: inline-flex;
    align-items: flex-start;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    flex: 0 0 auto;
    margin-bottom: 10px;

    &--full-width {
      display: flex;
      padding: 0;
      flex: 0 0 100%;
      align-items: center;
      justify-content: center;
    }

    &--full-page-stretch {
      // Padding stretches full-width of the screen minus some offset that first and last card has
      --scroller-container-stretch: calc((50vw - (var(--scroller-width) / 2)));

      &:first-child {
        padding-left: var(--scroller-container-stretch);
      }
      &:last-child {
        padding-right: var(--scroller-container-stretch);
      }
    }
  }
}
