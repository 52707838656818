
                  @import "@@/design-tokens/all.scss";
                
.image_wrapper {
  height: 200px;
  width: 200px;
  background-color: $primary-grey-tint-03;
  border-radius: 50%;
  aspect-ratio: 1;
  svg {
    width: 50%;
    object-fit: cover;
    max-width: initial;
  }
  &_picture {
    border-radius: 50%;
  }
  &_img {
    border-radius: 50%;
  }

  @media #{$stena-medium-and-down} {
    height: 180px;
    width: 180px;
  }

  @media #{$stena-small-and-down} {
    height: 160px;
    width: 160px;
  }
}
