
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
  position: relative;
}

.hero_split {
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
  grid-gap: 72px;
  background-color: $complementary-beige-tint-03;
  min-height: 513px;


  @media #{$stena-medium-and-down} {
    display: flex;
    flex-direction: column;
    gap: 0;
    background-color: $white;
  }

  &_left {
    @media #{$stena-large-and-up} {
      .hero_split_picture {
        order: 1;
      }

      .content_wrapper {
        order: 2;
        
      }
    }
  }

  &_right {
    @media #{$stena-large-and-up} {
      .hero_split_picture {
        order: 2;
      }

      .content_wrapper {
        order: 1;
        // margin-right: 100px;
        justify-content: flex-end;
      }
    }
  }
  hr {
    margin-left: 0;
  }
}
.content_wrapper{
  display: flex;
  align-items: center;
}
.notch {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  height: 41px;
  margin-top: -40px;
  background-color: $white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-width: 1208px;
  position: absolute;
  bottom: -1px;
  width: 100%;
  @media #{$stena-medium-and-down} {
    display: none;
  }
}
.hero_split_picture {
  height: 100%;
  width: 100%;
  img {
    object-fit: cover;
  }
}

.content {
  max-width: 516px;
  @media #{$stena-medium-and-down} {
    max-width: 100%;
    padding: 40px 56px;
    margin: -40px 28px 0 28px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 2;
    background-color: $white;
  }
  @media #{$stena-small-and-down} {
    padding: 40px 24px;
    margin: -40px 12px 0 12px;
  }
}

.button_wrapper {
  display: flex;
  gap: 24px;
  width: 100%;

  @media #{$stena-medium-and-down} {
    flex-wrap: wrap;
  }
  @media #{$stena-small-and-down} {
    flex-direction: column;
  }
}
