
                  @import "@@/design-tokens/all.scss";
                
.word_wrapper {
    display: inline-block;
    margin-right: 5px;
}

.suggestion_button {
    background: none;
    border: 0;
    text-decoration: underline;
    color: $complementary-blue;
    padding: 0;
}