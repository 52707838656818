
                  @import "@@/design-tokens/all.scss";
                
.map {
    width: 100%;
    height: 100%;
    min-height: 250px;
    min-width: 250px;
    max-height: 100%;
    max-width: 100%;
}