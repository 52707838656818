
                  @import "@@/design-tokens/all.scss";
                
:global {
  .top-menu {
    transform: translateY(0px);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    transform-origin: 100% 0;
    will-change: transform;
    z-index: 250;
    height: 50px;
    top: 0px;
    position: relative;
    @media #{$stena-small-and-down} {
      display: none;
    }
  }

  .top-menu.top-menu-hide {
    transform: translateY(-50px);
  }

  .top-menu.top-menu-sticky {
    position: sticky;
  }
}

.content_width {
  width: 100%;
  max-width: 1920px;
  padding: 0 40px;
  gap: 24px;
}

.mobile_container {
  display: none;

  @media #{$stena-small-and-down} {
    display: block;
    padding: 30px 0;
    width: 100%;

    .item {
      width: 80vw;
      padding: 0 30px 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .market_item {
      height: 36px;
      width: 80vw;
      padding: 0 30px 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.market_selector__mobile {
  white-space: nowrap;
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile_buttons {
  width: calc(80vw - 48px);
  margin: 20px 24px 0px 24px;
}

.header {
  width: 100%;
  height: 50px;
  background-color: $white;
  display: flex;
  justify-content: center;

  svg {
    height: 16px;
    margin-bottom: 2px;
  }

  @media #{$stena-small-and-down} {
    display: none;
  }

  &_recycling {
    background-color: rgba($primary-green-tint-03, 0.5);

    .item,
    .market_item {
      color: $primary-green;

      svg {
        fill: $primary-green;
      }
    }
  }

  &_metall {
    background-color: rgba($primary-blue-tint-03, 0.5);

    .item,
    .market_item {
      color: $primary-blue;
      svg {
        fill: $primary-blue;
      }
    }
  }

  &_steel {
    background-color: rgba($primary-grey-tint-03, 0.5);

    .item,
    .market_item {
      color: $primary-grey;
      svg {
        fill: $primary-grey;
      }
    }
  }

  &_oil {
    background-color: rgba($complementary-beige-tint-02, 0.5);

    .item,
    .market_item {
      color: $primary-grey;
      svg {
        fill: $primary-grey;
      }
    }
  }
  &_aluminium {
    background-color: rgba($primary-grey-tint-03, 0.5);

    .item,
    .market_item {
      color: $primary-grey;
      svg {
        fill: $primary-grey;
      }
    }
  }

  &_header-container {
    height: 100%;
  }
}
.item {
  white-space: nowrap;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: ease 0.2s all;

  background-color: transparent;
}

.item:hover {
  opacity: 0.9;
}

.icon_container {
  svg {
    height: 10px;
  }
}
