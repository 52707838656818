
                  @import "@@/design-tokens/all.scss";
                
.button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: $complementary-blue;
    display: flex;
    white-space: nowrap;
    
    &:hover:not([disabled]) {
        @media (hover: hover) and (pointer: fine) {
            color: $complementary-blue-tint-02;
          }
    }

    &:disabled {
        color: $complementary-charcoal-tint-04;
        cursor: unset;
    }

    
    &__reset {
        margin-bottom: 16px;
        gap: 8px;
        align-items: center;
        position: relative;
        z-index: 20;
        white-space: pre-wrap;
        text-align: left;

        svg {
            height: 18px;
            width: 18px;
        }
        div {
            margin-top: 3px;
        }
    }
    
    &__get_location, &__toggle_view {
        @media #{$stena-small-and-down} {
            max-width: 50%;
        }
        gap: 6px;
        align-items: flex-start;
        text-align: left;

        > div {
            white-space: pre-wrap;
        }

        svg {
            min-height: 22px;
            min-width: 20px;
            max-height: 22px;
            max-width: 20px;

        }
    }

    &__toggle_view {
        @media #{$stena-medium-and-up} {
            display: none;
        }
    }
}

.results_wrapper {
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media #{$stena-small-and-down} {
        height: 100%;
        min-height: 500px;
    }
}

.branchlist_wrapper {
    max-width: 380px;
    width: 100%;
    height: inherit;
    > div {
        height: inherit;
        overflow-y: auto;
        padding-right: 20px;
        @media #{$stena-small-and-down} {
            padding-right: 0;
            overflow-y: visible;
        }
    }

    @media #{$stena-medium-and-down} {
        max-width: 330px;
    }

    @media #{$stena-small-and-down} {
        max-width: 100%;
        width: 100%;
        height: 100%;
        &__is_hidden {
            display: none;
        }
    }
}

.map_wrapper {
    flex: 1;
    width: 100%;
    @media #{$stena-small-and-down} {
        position: absolute;
        max-width: 100%;
        width: 100%;
        height: 500px;
        z-index: 10;
        &__is_hidden {
            opacity: 0;
            z-index: -1;
        }
    }
}

.marked_location {
    display: none;
    background: $complementary-beige-tint-04;
    @media #{$stena-small-and-down} {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 11;
    }
}