
                  @import "@@/design-tokens/all.scss";
                
.sublevel_card {
  flex: 1;
  max-width: 560px;
  overflow: visible;

  a {
    text-decoration: none;
  }

  > a {
    width: 100%;
  }

  @media #{$stena-medium-and-down} {
    max-width: 100%;
  }
  h5 {
    padding: 24px;
  }
  .balance {
    text-wrap: balance;
  }
}

.card_content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.intro_wrapper {
  flex-grow: 1;
  margin-bottom: 0;
  > :first-child {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.button_wrapper {
  padding-left: 24px;
  button,
  a {
    align-self: start;

    > :last-child {
      padding-top: 3px;
    }
  }
}

.skeleton {
  &_card {
    background-color: $white;
    max-width: 560px;
    width: 100%;
    border-radius: 5px;
  }
  &_header {
    height: 25px;
    width: 110px;
    @media #{$stena-medium-and-down} {
      height: 25px;
      width: 110px;
    }
  }

  &_image {
    height: 245px;
  }

  &_intro {
    height: 100px;
  }

  &_button {
    margin-top: 24px;
    height: 20px;
    width: 85px;
  }
}
