
                  @import "@@/design-tokens/all.scss";
                
.company_card {
  &_large {
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    @media #{$stena-medium-and-down} {
      flex-direction: row-reverse;
    }
    @media #{$stena-small-and-down} {
      flex-direction: column-reverse;
    }
  }

  a {
    color: $info;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  a:hover {
    opacity: 0.9;
    text-decoration: underline;
  }

  .card_line {
    border: 0;
    border-bottom: 2px solid $primary-grey;
    margin-left: 0;
    margin-top: 9px;
    margin-bottom: 12px;

    &_large {
      margin-bottom: 16px;
    }
  }
}

.phonenumber {

  &:hover {
    color: $primary-grey !important;
    text-decoration: none !important;
  }
}


.image_wrapper {
  width: 50%;
  @media #{$stena-small-and-down} {
    width: 100%;

    img {
      border-radius: 4px 4px 0 0;
    }
  }
}

.cards_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  &__twocards {
    grid-template-columns: repeat(2, minmax(auto, 360px));
    justify-content: center;
  }

  @media #{$stena-medium-and-down} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.header_wrapper {
  h2,
  p {
    max-width: 820px;
  }
}
