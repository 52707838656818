
                  @import "@@/design-tokens/all.scss";
                
.heading_wrapper {
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  font-weight: normal;
}

.usp_container {
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

.usp_grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 260px);
  justify-content: center;
  grid-gap: 40px;
  @media #{$stena-medium-and-down} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: 100%;
  }
}

.cards_wrapper {
  max-width: 1160px;
  display: flex;
}
.card_wrapper {
  width: 260px;
}
