
                  @import "@@/design-tokens/all.scss";
                

$justifyContent: (
  flex-start,
  center,
  flex-end,
  space-between,
  space-evenly,
  space-around
);
$alignItems: (
  flex-start,
  center,
  flex-end,
  space-between,
  space-evenly,
  space-around
);
.Box {
  &_flex {
    display: flex;
  }
  &_flex_direction_row {
    flex-direction: row;
  }
  &_flex_direction_column {
    flex-direction: column;
  }
  @each $value in $justifyContent {
    &_justify_content_#{$value} {
      justify-content: $value;
    }
  }
  @each $value in $alignItems {
    &_align_items_#{$value} {
      align-items: $value;
    }
  }
}
