
                  @import "@@/design-tokens/all.scss";
                
.collage_hero {
  display: grid;
  grid-template-rows: 60% 40%;
  gap: 2px;

  @media #{$stena-medium-and-down} {
    display: flex;
    flex-direction: column;
  }

  @media #{$stena-large-and-up} {
    grid-template-rows: none;
    grid-template-columns: 60% 40%;
    height: 450px;
    gap: 5px;
  }

  &.collage_hero__three {
    grid-template-rows: 1fr 1fr 1fr;
    
    @media #{$stena-large-and-up} {
      grid-template-rows: none;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .collage_hero__item__content {
      flex-direction: column;
    }
  }
}

.collage_hero__item {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  min-height: calc((100vw / 16) * 9);
  display: flex;
  align-items: flex-end;

  @media #{$stena-large-and-up} {
    aspect-ratio: 16/9;
    min-height: auto;

    &_square {
      aspect-ratio: 2/1;
    }
    &_wide {
      aspect-ratio: 21/9;
    }
  }

  @media #{$stena-medium-and-up} {


    &:hover {
      picture {
        transform: scale(1.1);
      }
      .collage_hero__item_content__text {
        color: $stena-color-primary-grey-tint03;
      }
    }
  }
}

.collage_hero__item_image {
  position: absolute;
  inset: 0;
  z-index: -1;
  object-fit: cover;
  transition: transform .8s ease-in-out;
  width: 100%;
  height: 100%;
}

.collage_hero__item_content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;

  &__text {
    color: $stena-color-white;
  }
  &__arrow {
    color: $stena-color-white;
  }
}

.collage_hero__icon {
    flex-shrink: 0;
}

.collage_hero__item_shadow {
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: 54%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.75) 62.75%);
}

.collage_hero__item_container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  width: 100%;

  @media #{$stena-medium-and-up} {
    gap: 12px;
    padding: 24px 40px;
  }
}
.collage_hero__item_link {
  position: absolute;
  inset: 0;
}

.collage_hero:not(.collage_hero__three) {
  .collage_hero__item {
    &:first-of-type {
      .collage_hero__item_container {
        // Align text in first image with content
        max-width: calc(100% - calc((100vw - 1250px) / 2));
      }
      .collage_hero__item_shadow {
        justify-content: flex-end;
      }
    }
    &:last-of-type {
      .collage_hero__item_content {
        justify-content: flex-start;
      }
      .collage_hero__item_container {
        // Align end of text with search icon in MainMenu 
        max-width: calc(100% - calc((100vw - 1920px) / 2));
      }
    }
  }
}

.collage_hero__item_list {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 24px 44px;
  width: 100%;
  height: 100%;

  @media #{$stena-large-and-up} {
    justify-content: flex-end;
    padding: 0 48px 48px 48px ;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    color: $stena-color-complementary-charcoal;

    &:hover, &:active {
      color: $shades-blue-01;
    }
  }

  &__line {
    background-color: $stena-color-complementary-charcoal;
    width: 60px;
    height: 2px;
    margin-top: 7px;
    margin-bottom: 7px;

    @media #{$stena-large-and-up} {
      margin-top: 10px;
    }
  }
}