
                  @import "@@/design-tokens/all.scss";
                
.cards_wrapper {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(498px, max-content);
    @media #{$stena-medium-and-down} {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: minmax(430px, max-content);
    }
    @media #{$stena-small-and-down} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    &:hover {
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
    }
    border-radius: 5px;
    img {
      border-radius: 5px 5px 0 0;
    }
  }
  
  .link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }