
                  @import "@@/design-tokens/all.scss";
                
.countdown_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.clock_wrapper {
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 24px;
  @media #{$stena-medium-and-down} {
    gap: 16px;
    grid-template-columns: repeat(4, auto);
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: repeat(2, auto);
  }
}

.date_wrapper {
  :first-child {
    border-radius: 14px 0 0 14px;
  }

  :last-child {
    border-radius: 0 14px 14px 0;
  }

  &_disabled {
    .number_box {
      color: $primary-grey-tint-01;
      background-color: $primary-grey-tint-03;
    }

    p {
      color: $primary-grey-tint-01;
    }
  }

  &_disabled_beige {
    .number_box {
      background-color: $white;
    }
  }

  &_hidden {
    display: none;
  }
}

.number_box {
  background-color: $complementary-charcoal-tint-01;
  color: $white;
  margin: 0 0.5px;
  width: 80px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media #{$stena-medium-and-down} {
    height: 75px;
    width: 50px;
  }

  p {
    padding-top: 10px;
    font-family: "StenaNumber-Medium";
    font-size: 60px;
    font-style: normal;
    font-weight: 500;

    @media #{$stena-medium-and-down} {
      font-size: 34px;
      padding-top: 8px;
    }
    @media #{$stena-small-and-down} {
      font-size: 35px;
      padding-top: 5px;
    }
  }
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
