
                  @import "@@/design-tokens/all.scss";
                
@import "../../../styles/pages/content/index.scss";
$content-max-width: 1208px;

.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.hero_content {
  &__wrapper {
    width: 100%;
    @media #{$stena-large-and-up} {
      max-width: calc($sidebar-left-max-width + 40px);
    }
  }

  &__pretitle {
    display: inline-block;
    position: relative;
  }

  &__br {
    gap: 20px 0;

    & > span {
      position: relative;
    }
    & > span::after {
      content: " ";
      position: relative;
      margin: auto;
      left: 0px;
      right: 0;
      width: 40px;
      top: -11px;
      border-bottom: 2px solid $complementary-charcoal;
      display: inline-block;
    }
  }
}

.stroke {
  width: 60px;
  height: 0px;
  border: 0;
  border-bottom: 2px solid $complementary-charcoal;
  margin-top: 8px;
  margin-bottom: 0;
  margin-left: 0;
}

.published_date {
  svg {
    margin-bottom: 3px;
  }
}
