
                  @import "@@/design-tokens/all.scss";
                
.hero {
  background-color: $primary-grey-tint-03;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__notch_wrapper {
    width: 100%;
  }
  &__notch {
    min-height: 40px;
    max-width: 1208px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $white;
    position: relative;
    bottom: -1px;
  }

  &.hero_white {
    background-color: $stena-color-white;
  }
}
.breadcrumb_wrapper {
  position: relative;
}
hr {
  width: 60px;
  height: 0px;
  border: 0;
  border-bottom: 2px solid $complementary-charcoal;
  margin-top: 8px;
  margin-bottom: 24px;
}
.center_align {
  margin-left: auto;
}
.left_align {
  margin-left: 0;
}

.wrapper {
  max-width: 900px;
}
.button_wrapper {
  @media #{$stena-small-and-down} {
    flex-direction: column;
  }
}

.content_wrapper {
  > :first-child {
    max-width: 900px;
  }
}
