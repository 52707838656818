// -----------------------------------------------------
// This file was generated, do not modify this directly.
// change the design token-file and run 'npm run generate:tokens'
// -------------------------------------------------------------------

:root {
  --ui-color-black: #000000;
  --ui-color-white: #ffffff;
  --ui-color-primary-green: #657b6c;
  --ui-color-primary-grey: #62636a;
  --ui-color-primary-blue: #5a6c81;
  --ui-color-primary-green-tint01: #93a498;
  --ui-color-primary-green-tint02: #bfcac2;
  --ui-color-primary-green-tint03: #e5ebe6;
  --ui-color-primary-grey-tint01: #8c8a8f;
  --ui-color-primary-grey-tint02: #c7c5c9;
  --ui-color-primary-grey-tint03: #efefef;
  --ui-color-primary-blue-tint01: #7c95ab;
  --ui-color-primary-blue-tint02: #b2bfcc;
  --ui-color-primary-blue-tint03: #e5eaf0;
  --ui-color-primary-blue-tint04: #f2f4f7;
  --ui-color-complementary-orange: #a46351;
  --ui-color-complementary-beige: #c5baac;
  --ui-color-complementary-charcoal: #3f4347;
  --ui-color-complementary-blue: #327ea5;
  --ui-color-complementary-orange-tint01: #c18f81;
  --ui-color-complementary-orange-tint02: #d1ada3;
  --ui-color-complementary-orange-tint03: #e2cbc5;
  --ui-color-complementary-orange-tint04: #ebdad6;
  --ui-color-complementary-beige-tint01: #dbd3c8;
  --ui-color-complementary-beige-tint02: #ebe7e0;
  --ui-color-complementary-beige-tint03: #f2f1ed;
  --ui-color-complementary-beige-tint04: #f7f5f3;
  --ui-color-complementary-charcoal-tint01: #565c61;
  --ui-color-complementary-charcoal-tint02: #6e757c;
  --ui-color-complementary-charcoal-tint03: #888f95;
  --ui-color-complementary-charcoal-tint04: #c9cccf;
  --ui-color-complementary-blue-tint01: #62abd0;
  --ui-color-complementary-blue-tint02: #89c0dc;
  --ui-color-complementary-blue-tint03: #b1d5e7;
  --ui-color-complementary-blue-tint04: #d8eaf3;
  --ui-color-shades-blue01: #478bae;
  --ui-color-shades-blue02: #286584;
  --ui-color-shades-green01: #5f7466;
  --ui-color-shades-green02: #55675a;
  --ui-color-shades-charcoal01: #3f4347;
  --ui-color-shades-charcoal02: #323639;
  --ui-color-shades-warning01: #E5DFC4;
  --ui-color-info: #327ea5;
  --ui-color-success: #98b347;
  --ui-color-warning: #e0b400;
  --ui-color-danger: #de5c45;
  --ui-color-info-tint: #e4f4fb;
  --ui-color-success-tint: #e8f3d3;
  --ui-color-warning-tint: #fef4c8;
  --ui-color-danger-tint: #fff0eb;
  --ui-color-chart01: #c68d6c;
  --ui-color-chart02: #98b347;
  --ui-color-chart03: #327ea5;
}