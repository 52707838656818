
                  @import "@@/design-tokens/all.scss";
                
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 40px;
  max-width: 1200px;
  justify-items: center;
  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr;
  }
}

.button_wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 40px 18px 0 18px;
  @media #{$stena-medium-and-down} {
    max-width: 760px;
  }
}
.scroller_container {
  width: 100%;
}
.scroller {
  width: 100%;
}
.scroller_item {
  width: 332px;
  @media #{$stena-small-and-down} {
    width: 100%;
  }
}
