
                  @import "@@/design-tokens/all.scss";
                
.hero_wrapper {
  position: relative;
  min-height: 85vh;
  height: auto;
  display: flex;
  align-items: center;

  @media #{$stena-small-and-down} {
    min-height: 85vh;
    height: fit-content;
  }

  &__noimage {
    min-height: auto;
  }
}

.preview {
  min-height: 1000px !important;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 50px;
  left: 40px;
  z-index: 1;

  @media #{$stena-small-and-down} {
    top: 20px;
    left: 20px;
  }

  img {
    height: 44.44px;
    opacity: 0.85;
    @media #{$stena-small-and-down} {
      height: 40.37px;
    }
  }

  &__small {
    img {
      height: 24.4px;
      @media #{$stena-small-and-down} {
        height: 22.2px;
      }
    }
  }
}

.content {
  z-index: 1;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media #{$stena-small-and-down} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &__large {
    padding-top: 130px;
    padding-bottom: 100px;
    @media #{$stena-small-and-down} {
      padding: 40px;
      padding-top: 100px;
      padding-bottom: 64px;
    }
  }

  &__small {
    padding-top: 110px;
    padding-bottom: 110px;
    @media #{$stena-small-and-down} {
      padding-top: 80px;
      padding-bottom: 64px;
    }
  }
  &__noimage {
    padding-top: 140px;
    padding-bottom: 40px;
  }
  &__noimage_small {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  * {
    max-width: 516px;
  }

  &__center {
    align-items: center;
    * {
      max-width: 760px;
    }
  }

  &__end {
    align-items: end;
  }

  
}

.line {
  width: 60px;
  height: 0px;
  border: 0;
  border-bottom: 2px solid $white;
  color: $white;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 40px;

  @media #{$stena-small-and-down} {
    margin-bottom: 32px;
  }

  &__dark {
    border-color: $complementary-charcoal;
  }
}

.pause_play_icon {
  position: absolute;
  bottom: 74px;
  left: 50px;
  width: 60px;
  height: 60px;
  color: $white;

  @media #{$stena-small-and-down} {
    width: 40px;
    height: 40px;
    bottom: 60px;
    left: 20px;
  }

  &__campaign {
    bottom: 44px;
    @media #{$stena-small-and-down} {
      bottom: 20px;
    }
  }

  &__pause {
    @media #{$stena-small-and-down} {
      display: none;
    }
  }
}

.notch {
  position: absolute;
  bottom: -1px;
  background-color: $white;
  height: 40px;
  max-width: calc(1208px + 24px);
  width: calc(100% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 5px 5px 0 0;
  z-index: 1;
  &__image {
    @media #{$stena-small-and-down} {
      top: 530px;
    }
  }
}
