
                  @import "@@/design-tokens/all.scss";
                
.dropdown {
    min-width: 200px;
    max-width: 300px;
    width: fit-content;
    &__fullwidth {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
}

.dropdown_button {
    position: relative;
    border: 1px solid $primary-grey;
    border-radius: 5px;
    cursor: pointer;

    &__disabled {
        border-color: rgb($primary-grey, 0.5);
        color: rgb($primary-grey, 0.5);
        cursor: initial;
    }

    &__text {
        position: relative;

        &_truncation {
            overflow: hidden;
            white-space: nowrap;
        }
        

        &__placeholder {
            &_hidden {
                opacity: 0;
                max-height: 23px;
            }
            &_truncation {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &__selectedvalues {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 4px;
        gap: 4px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.dropdown_menu_wrapper {
    box-shadow: 0px 15px 40px 0px #33333326;
    background-color: $white;
    max-height: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 10;
    border-bottom-style: solid;
    border-bottom-color: $white;
}

.chevron_icon {
    min-height: 5px;
    height: 5px;
    min-width: 10px; 
    width: 10px;
    margin-left: 12px;

    transition-duration: 0.3;
    transition-property: transform;

    &__open {
        transform: rotate(180deg);
    }
}

.count {
    white-space: nowrap;
}