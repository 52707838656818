
                  @import "@@/design-tokens/all.scss";
                
@keyframes scaleUp {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacityOverlay {
  0% {
    background: transparent;
  }
  100% {
    background: rgba(0, 0, 0, 0.6);
  }
}

.container {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.6);
  animation: opacityOverlay 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  z-index: 1000;
}

.lightbox {
  max-height: 100%;
  max-width: 90%;
  overflow: hidden;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  background-color: $primary-grey-tint-03;
  @media #{$stena-small-and-down} {
    height: 100%;
    max-width: 100%;
  }
}

.toolbar {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  z-index: 100;
  position: relative;
  &_top {
    justify-content: flex-end;
  }
  &_bottom {
    justify-content: center;
  }
}

.zoom_text {
  color: $primary-grey;
  padding: 8px;
  border: 1px solid $primary-grey-tint-02;
  border-right: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom_btn {
  border: 1px solid $complementary-blue;
  color: $complementary-blue;
  padding: 8px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &_in {
    margin-left: -1px;
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.zoom_btn:disabled {
  border: 1px solid $primary-grey-tint-02;
  background: $primary-grey-tint-03;
  color: $primary-grey-tint-02;
}
