
                  @import "@@/design-tokens/all.scss";
                
.button {
  background: none;
	color: $primary-grey;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;

  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  @media #{$stena-medium-and-down} {
    max-width: 32px;
    min-width: 32px;
    max-height: 32px;
    min-height: 32px;
    }
  svg {
    width: 100%;
    height: 100%;
  }
  &:hover:not([disabled]) {
    color: $primary-grey-tint-01;
  }
  &__hidden {
    cursor: unset;
    opacity: 0;
    &:focus {
      outline: none;
    }
  }
}

.button_wrapper {
  overflow: visible;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  @media #{$stena-medium-and-down} {
    max-width: 32px;
    min-width: 32px;
    max-height: 32px;
    min-height: 32px;
    }
}