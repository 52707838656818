
                  @import "@@/design-tokens/all.scss";
                
.highlight_cta {
  display: flex;
  flex-direction: column;
  
  @media #{$stena-large-and-up} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 50px;
  }
}

.highlight_cta__content {
  max-width: 744px;
}