
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
    position: relative;
}

.input_wrapper {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    &:focus-within {
        border-color: rgba(0, 0, 0, 0.5);
        .button__search {
            color: rgba(0, 0, 0, 0.5);
        }
    }
    
    @import "../../elements/Typography/style/typography.module.scss";
    input {
        @extend .typography;
        @extend .intro;
        color: $complementary-charcoal;
        line-height: 36px;
        border: 0;
        padding: 20px 72px 16px 72px;
        width: 100%;
        border-radius: 4px;
        appearance:none;
        background: $white;
        text-overflow: ellipsis;
        cursor: text;

        &:focus {
            outline: none;
        }

        @media #{$stena-medium-and-down} {
            padding: 8px 44px 2px 16px;
        }
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
    display: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none; // latest Opera versions support -webkit-
    user-select: none;
    }

    

    &__menu_search {
        border: 0;
        border-bottom: 1px solid $primary-grey-tint-03;
        border-radius: 0;

        input {
            padding: 16px 60px 12px 36px;
            @media #{$stena-medium-and-down} {
                padding: 16px 36px 12px;
            }
        }

        @media #{$stena-medium-and-down} {
            border: 1px solid $primary-grey-tint-02;
            border-radius: 5px;
            margin: 24px 24px 32px;
        }

        &:focus-within {
            border-color: $primary-grey-tint-03;
        }
        
    }
}

.button {
    display: flex;
    cursor: pointer;
    background: none;
    color: $complementary-charcoal-tint-03;
    border: none;
    position: absolute;
    &__search {
        padding: 20px;
        left: 2px;
        @media #{$stena-medium-and-down} {
            display: none;
        }
    }
    &__reset {
        padding: 20px;
        right: 2px;
        @media #{$stena-small-and-down} {
            padding: 12px;
        }
    }

    &:hover:not([disabled]) {
        color: rgba(0, 0, 0, 0.7);
    }

    &:disabled {
        cursor: unset;
    }

    svg {
        height: 24px;
        @media #{$stena-medium-and-down} {
            height: 12px;
        }
    }
}

.autocomplete_wrapper {
    position: relative;
    
    > :first-child {
        padding-left: 0;
        position: absolute;
        width: 100%;
        max-width: 500px;
        top: 8px;
        background: $white;
        box-shadow: 0px 15px 40px rgba(51, 51, 51, 0.15);
        border-radius: 5px;
        z-index: 10;
        margin: 0;
    }
}

.autocomplete_item {
    cursor: pointer;
    list-style-type: none;
   
    :hover {
        color: $stena-color-info;
    }
}