
                  @import "@@/design-tokens/all.scss";
                

@mixin aspectRatio($bp) {
  .ui-#{$bp}-aspect-ratio {
    aspect-ratio: var(--ui-#{$bp}--aspect-ratio); 
  }
}

@include aspectRatio(sm);
@media #{$stena-medium-and-up} {
  @include aspectRatio(md);
}
@media #{$stena-large-and-up} {
  @include aspectRatio(lg);
}