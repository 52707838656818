
                  @import "@@/design-tokens/all.scss";
                


.scroller_item {
    width:360px;
    @media #{$stena-medium-and-down} {
        width:330px;
      }
      @media #{$stena-small-and-down} {
       width:100%;
      }
}

.cards_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

    @media #{$stena-medium-and-down} {
  grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;   
   *:nth-child(2n-1):nth-last-of-type(1) {
          grid-column: span 2;
          :first-child {
            align-items: center;
          }
        }
      }
      @media #{$stena-small-and-down} {
       display: flex;
       flex-direction: column;
       margin: 8px 0 8px 0;
      }
}


.grid {
    display: grid;
  grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
      @media #{$stena-medium-and-down} {

        grid-gap: 16px;
      }
      @media #{$stena-small-and-down} {
        
        grid-template-columns: repeat(1, 100%);
      }
}