
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    @media #{$stena-medium-and-down} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media #{$stena-small-and-down} {
        grid-template-columns: repeat(1, 1fr);
    }
}