
                  @import "@@/design-tokens/all.scss";
                
.divider {
    width: 36px;
    height: 2px;
    background: $complementary-charcoal;
}

.content {
    max-width: 460px;
}

.error_image {
    display: flex;
    justify-content: center;
    picture {
        aspect-ratio: auto;
    }
    img {
        width: 100%;
        max-width: 310px;
        object-fit: fill;
    }
}