
                  @import "@@/design-tokens/all.scss";
                
.banner {
  padding: 16px 44px;
  @media #{$stena-small-and-down} {
    padding: 24px;
  }

  &_danger {
    background-color: $stena-color-complementary-orange-tint04;
    border-bottom: 1px solid $stena-color-complementary-orange-tint03;
  }
  &_warning {
    background-color: $stena-color-warning-tint;
    border-bottom: 1px solid $stena-color-shades-warning01;
  }
  &_information {
    background-color: $stena-color-info-tint;
    border-bottom: 1px solid $stena-color-complementary-blue-tint04;
  }

  a {
    color: $stena-color-complementary-charcoal;
    text-decoration: underline;

    &:hover {
      color: $stena-color-complementary-charcoal-tint01;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    @media #{$stena-small-and-down} {
      width: 20px;
      height: 20px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media #{$stena-large-and-down} {
      flex-direction: column;
      gap: 0;
    }
  }

  &__body {
    flex-shrink: 1;
    text-align: center;

    @media #{$stena-extra-large-and-up} {
      text-align: left;
    }

    @media #{$stena-small-and-down} {
      text-align: left;
    }
  }

  &__headline {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    
    @media #{$stena-extra-large-and-up} {
      align-items: center;
      align-self: flex-start;
      max-width: 40%;
    }
  }
}