
                  @import "@@/design-tokens/all.scss";
                
.stena-picture {
  position: relative;
  height: auto;
  width: 100%;

  &__img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--loading {
      visibility: hidden;
    }

    &--missing {
      background: rgba(0, 0, 0, 0.04);
      object-fit: contain;
    }
  }

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }

  &--loading {
    background-color: $stena-color-primary-grey-tint03;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      $stena-color-primary-grey-tint03;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: loading 2s ease-in-out infinite;

    &--circular {
      border-radius: 50%;
    }
  }
}

// :global(.lazyloading) {

//   background-color: $stena-color-primary-grey-tint03;
//   background: linear-gradient(
//     100deg,
//     rgba(255, 255, 255, 0) 40%,
//     rgba(255, 255, 255, .5) 50%,
//     rgba(255, 255, 255, 0) 60%
//   ) $stena-color-primary-grey-tint03;
//   background-size: 200% 100%;
//   background-position-x: 180%;
//   animation: 2s loading ease-in-out infinite;
// }

