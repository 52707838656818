
                  @import "@@/design-tokens/all.scss";
                
.image img {
  border-radius: 5px 5px 0px 0px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border-radius: 5px;

  &_large {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    .image img {
      height: 240px;
    }
    @media #{$stena-medium-and-down} {
      width: 100%;
      max-width: 100vw;

      .image img {
        height: 292px;
      }
    }
  }

  &_small {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .image img {
      height: 216px;
    }
  }

  &_medium {
    width: 100%;
    flex-direction: row;
    flex: 1;
    max-height: fit-content;
    width: 100%;
    max-width: 100vw;

    .image {
      max-width: 40%;

      img {
        border-radius: 5px 0px 0px 5px;
      }
    }
  }
}

.text_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card_grid {
  width: 100%;
}

.button_wrapper {
  width: 100%;

  button,
  a {
    max-width: 210px;
    @media #{$stena-small-and-down} {
      width: 100%;
      max-width: 100%;
    }
  }
}
