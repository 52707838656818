
                  @import "@@/design-tokens/all.scss";
                
:global {
  --grid-background: $primary-grey-tint-03;
  .beige {
    --grid-background: #ebe7e0;
  }
  .white {
    --grid-background: #efefef;
  }
  .embedded-form-container {
    // hubspot forms
    form {
      position: relative;
      margin: 0 auto !important;
      padding-bottom: 72px;
      max-width: 764px !important;
      font-family: "Stena Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $primary-grey;

      fieldset {
        border: none;
        display: flex;
        gap: 32px;
        max-width: 100% !important;
        div {
          width: 100%;
        }
      }

      ul {
        list-style: none;
        padding: 0;
      }

      .invalid,
      .error {
        border: 1px solid $danger !important;
      }
      .hs-error-msg {
        margin-top: 0;
        color: $danger;
      }

      ul.hs-error-msgs {
        margin-top: -10px;
        color: $danger;
      }
      a {
        color: $stena-color-info;
        text-decoration: none;

        &:hover:not(.base_button) {
          text-decoration: underline;
        }
      }

      h1 {
        font-size: 40px;
        line-height: 45px;
        letter-spacing: 1.2px;

        @media #{$stena-medium-and-down} {
          font-size: 35px;
          line-height: 42px;
        }

        @media #{$stena-small-and-down} {
          font-size: 28px;
          line-height: 32px;
          letter-spacing: 3px;
        }
      }

      h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 3px;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          /* or 115% */

          letter-spacing: 3px;
          text-transform: uppercase;
        }

        @media #{$stena-small-and-down} {
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 3px;
          text-transform: uppercase;
        }
      }
      p {
        margin-bottom: 32px;
      }
      h3 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 2px;

        @media #{$stena-medium-and-down} {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }

        @media #{$stena-small-and-down} {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
      }

      h4 {
        font-weight: normal;
        font-size: 27px;
        line-height: 32px;
        letter-spacing: 1.2px;

        @media #{$stena-medium-and-down} {
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          line-height: 32px;
          /* or 128% */

          letter-spacing: 0.9px;
        }

        @media #{$stena-small-and-down} {
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.9px;
        }
      }

      h5 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 17px;
        line-height: 23px;
        letter-spacing: 2px;

        @media #{$stena-medium-and-down} {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }

        @media #{$stena-small-and-down} {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
      }

      button,
      input[type="submit"] {
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        min-height: 40px;
        min-width: 180px;
        max-width: 250px;
        text-transform: uppercase;
        font-family: "Stena Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 2.5px;
        border: 0;
        cursor: pointer;
        border-radius: 0 !important;
        position: absolute;
        bottom: 0;
        right: 0;
        background: $complementary-blue;
        color: white;
        padding: 10px 24px 8px 24px;
        white-space: wrap;

        @media #{$stena-small-and-down} {
          width: 100%;
          max-width: 100%;
        }
      }

      .hs-form__field-row__column {
        display: flex;
        flex: 1 1 auto;
      }

      &__row {
        margin: 24px 0;
      }

      .hs-form__field-row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 24px 0;
        gap: 32px;
      }
      .hs-form__field {
        display: flex;
        flex-direction: column;
        width: 100%;

        //TODO: design to be decided
        input[inputmode="numeric"] {
          width: 70px !important;
          padding: 10px;
          font-size: 16px;
          margin: 0;
        }

        .hs-form__date_input__separator {
          margin: 4px !important;
        }

        //TODO: styling on fileupload
        input[type="file"] {
          display: none;
          height: 0px;
        }

        label[for="file-input"] {
          -moz-appearance: none !important;
          -webkit-appearance: none !important;
          appearance: none !important;
          height: 40px;
          width: 180px;
          cursor: pointer;
          border-radius: 0 !important;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 2.5px;
          border: 1px solid #327ea5;
          color: #327ea5;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      input[type="text"],
      input[type="number"],
      input[type="url"],
      input[type="email"],
      input[type="tel"],
      textarea,
      select {
        flex: 1 1 auto;
        padding: 10px;
        border: 1px solid $primary-grey-tint-02;
        border-radius: 5px;
        color: $complementary-charcoal;
        width: 100% !important;
        margin-bottom: 12px;
        margin-top: 0;
      }

      div > input[type="text"],
      div > input[type="number"],
      div > input[type="url"],
      div > input[type="email"],
      div > input[type="tel"],
      table,
      textarea,
      select {
        margin: 4px 0 24px;
      }

      tr {
        input[type="radio"] {
          margin: 10px;
        }
      }

      td {
        div {
          display: flex;
          justify-content: center;
        }
      }

      tr:nth-child(even) {
        background-color: var(--grid-background);
      }
      select {
        overflow-y: auto;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        &:not([multiple]) {
          background: $white;
          background-image: url("./../../epiforms/icons/formChevronDown.svg");
          background-repeat: no-repeat;
          background-position-x: calc(100% - 10px);
          background-position-y: 50%;
        }
        option {
          padding: 10px;
          color: $complementary-charcoal;
          width: 100%;
          &:not([disabled]) {
            cursor: pointer;
          }
        }
      }

      select[multiple] {
        :first-child {
          display: none;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        accent-color: $complementary-blue;
        width: 16px;
        height: 16px;
        margin: 4px 8px 24px 0;
      }

      label {
        margin-right: 12px;
      }

      textarea {
        max-height: 100%;
      }

      input[aria-invalid="true"] {
        border: 1px solid $danger;
      }

      .form-columns-2 {
        @media #{$stena-small-and-down} {
          display: flex;
          flex-direction: column;
          padding: 0;
          gap: 0;
          input {
            margin-bottom: 24px;
          }
        }
      }

      &__richtext {
        img {
          //TODO: styling on images?
          border-radius: 5px;
        }
        //TODO: styling on all headings, p, pre, blockquote
      }

      &__field__checkbox__label,
      &__field__radio__label {
        height: 43px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        span {
          padding-left: 10px;
        }
      }
    }
  }
}

.form_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media #{$stena-small-and-down} {
    grid-template-columns: 100%;
    gap: 16px;
  }
}
