
                  @import "@@/design-tokens/all.scss";
                

$flex-container-props: (
  align-items
  (
    baseline,
    center,
    flex-end,
    flex-start,
    stretch,
    inherit,
    initial,
    unset
  ),
  align-content
  (
    flex-start,
    flex-end,
    center,
    space-between,
    space-around,
    stretch
  ),
  justify-content
  (
    flex-start,
    flex-end,
    center,
    space-between,
    space-around,
    space-evenly
  ),
  flex-wrap
  (
    nowrap,
    wrap,
    wrap-reverse
  ),
  flex-direction
  (
    row,
    row-reverse,
    column,
    column-reverse
  )
);

$flex-item-props: (
  flex-grow
  (
    0,
    1,
    2,
    3
  ),
  flex-shrink
  (
    0,
    1,
    2,
    3
  ),
  flex-basis
  (
    0,
    "auto"
  ),
  justify-self
  (
    center,
    flex-end,
    flex-start,
    stretch
  ),
  align-self
  (
    center,
    flex-end,
    flex-start,
    stretch,
  )
);

@mixin flexClasses($breakpoint) {
  @each $prop, $values in $flex-container-props {
    @each $v in $values {
      &--#{$breakpoint}-#{$prop}-#{$v} {
        #{$prop}: $v;
      }
    }
  }
  
  @each $prop, $values in $flex-item-props {
    @each $v in $values {
      &--#{$breakpoint}-#{$prop}-#{$v} {
        #{$prop}: $v;
      }
    }
  }
}

@mixin gapClasses($breakpoint) {
  &--#{$breakpoint}-gap { gap: calc(var(--ui-#{$breakpoint}-gap) * 4px); }
  &--#{$breakpoint}-order { order: var(--ui-#{$breakpoint}-order); }
}

.ui-flex {
  &--container {
    display: flex;
  }

  @include flexClasses(sm);
  @include gapClasses(sm);
  @media #{$stena-medium-and-up} {
    @include flexClasses(md);
    @include gapClasses(md);
  }

  @media #{$stena-large-and-up} {
    @include flexClasses(lg);
    @include gapClasses(lg);
  }
}