
                  @import "@@/design-tokens/all.scss";
                
@mixin sizeClasses($bp) {
  .ui-size--#{$bp}-width-auto { width: auto; }
  .ui-size--#{$bp}-height-auto { height: auto; }
  .ui-size--#{$bp}-width-full { width: 100%; }
  .ui-size--#{$bp}-height-full { height: 100%; }
  .ui-size--#{$bp}-size-auto { 
    width: auto;
    height: auto; 
  }
  .ui-size--#{$bp}-size-full { 
    width: 100%;
    height: 100%; 
  }
  .ui-size--#{$bp}-width {
    width: calc(var(--ui-#{$bp}-w) * 4px);
  }
  .ui-size--#{$bp}-height {
    height: calc(var(--ui-#{$bp}-h) * 4px);
  }
  .ui-size--#{$bp}-size {
    width: calc(var(--ui-#{$bp}-s) * 4px);
    height: calc(var(--ui-#{$bp}-s) * 4px);
  }
}

@include sizeClasses(sm);
@media #{$stena-medium-and-up} {
  @include sizeClasses(md);
}
@media #{$stena-large-and-up} {
  @include sizeClasses(lg);
}