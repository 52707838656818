    .CookieDeclaration {
        padding-top: 50px;
        @import "../../elements/Typography/style/typography.module.scss";
        h2 { 
            @extend .typography, .heading3;
            margin-bottom: 24px; 
        }

        > :first-child {
            @extend .typography, .intro;
            max-width: 760px;
            padding: 0 10px;
        }


        .CookieDeclarationLastUpdated {
            @extend .typography, .body;
            padding: 0 10px;
            a {
                color: $stena-color-info;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        #CookieDeclarationUserStatusPanel {
            padding: 0 10px;
            #CookieDeclarationUserStatusLabelConsentId {
                word-break: break-all;
            }
        }

        .CookieDeclarationType {
            border: 0;
            margin: 52px 0 0 0;
            padding: 0;

            .CookieDeclarationTypeHeader {
                @extend .typography, .heading5;
                padding: 0 10px;
            }

            .CookieDeclarationTypeDescription {
                @extend .typography, .body;
                margin-top: 16px;
                max-width: 760px;
                padding: 0 10px;
            }

            table {
                display: block;
                overflow-x: auto;
                border-spacing: 0;
                max-width: calc(100vw - 40px);

                thead, tbody {
                    display: table;
                    width: 100%;
                }

                th, td {
                    min-width: 150px;
                    max-width: 150px;
                    line-break: anywhere;
                }

                
                    th {
                        @extend .typography, .button;
                        padding: 16px 10px;
                        margin: 0;
                        border: 0;
                        border-bottom: 1px solid $primary-blue-tint-02;
                        display: revert;
                        text-align: left;
                    }

                    td {
                        @extend .typography, .information;
                        border: 0;
                        padding: 20px 10px;
                        border: 0;
                        
                    }

                    tr:nth-child(even) {
                        background: $primary-blue-tint-03;
                    }
                    
            }
        }
    }
  