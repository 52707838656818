
                  @import "@@/design-tokens/all.scss";
                
$content-max-width: 760px;

.scroller_wrapper {
  display: flex;
}

.scroller_item {
  flex: auto;
  min-width: calc(50% - 20px);
  @media #{$stena-small-and-down} {
    min-width: 100%;
  }
}

.multi_wrapper {
  width: calc(50% - 20px);
  @media #{$stena-medium-and-down} {
    width: 100%;
  }
}

.full_wrapper {
  width: 100%;
}

.header_wrapper {
  > :first-child {
    max-width: 960px;
  }
}

$spacing: 354px;
.item_wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - calc($spacing/4));
  margin-top: calc($spacing/4);
}

.image_wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $spacing;
  top: -#{calc($spacing/2)};
}

$animation-time: 0.3s;
.content_wrapper {
  height: 100%;
  overflow: hidden;
  -webkit-transition: max-height $animation-time ease-in-out;
  -moz-transition: max-height $animation-time ease-in-out;
  -o-transition: max-height $animation-time ease-in-out;
  transition: max-height $animation-time ease-in-out;
  &__open {
    max-height: 100%;
  }
  &__closed {
    max-height: 100%;
    @media #{$stena-medium-and-down} {
      max-height: 700px;
    }
  }
}

.circular_picture {
  width: 160px;
}

.circular_image {
  border-radius: 50%;
  border: 7px solid $white;
}

.square_picture {
  width: 252px;
}

.square_image {
  border-radius: 5px;
  border: 7px solid $white;
}

.richText_wrapper {
  max-width: $content-max-width;
  overflow: hidden;
  position: relative;
}

.richText_fade {
  @media #{$stena-medium-and-down} {
    content:'';
    width:100%;
    height:100%;
    position:absolute;
    right:0;
    top:0;

    $fade-from: rgba(0,0,0,0) 75%;
    $fade-to: 95%; 

    &__grey {
      background: linear-gradient(180deg, $fade-from, $primary-grey-tint-03 $fade-to);
    }
    &__green {
      background: linear-gradient(180deg, $fade-from, $primary-green-tint-03 $fade-to);
    }
    &__blue {
      background: linear-gradient(180deg, $fade-from, $primary-blue-tint-03 $fade-to);
    }
    &__orange {
      background: linear-gradient(180deg, $fade-from, $complementary-orange-tint-04 $fade-to);
    }
    &__beige {
      background: linear-gradient(180deg, $fade-from, $complementary-beige-tint-03 $fade-to);
    }
  }
}

.buttons_wrapper {
  max-width: $content-max-width;
}

.readMoreButton {
  @media #{$stena-large-and-up} {
    display: none;
  }
  max-width: $content-max-width;
  button {
    padding-left: 0;
  }

  svg {
    margin-top: -2px;
  }
}
