
                  @import "@@/design-tokens/all.scss";
                

@mixin borderRadius($bp) {
  .ui-#{$bp}-br { border-radius: calc(var(--ui-#{$bp}-br) * 4px); }
}

@include borderRadius(sm);
@media #{$stena-medium-and-up} {
  @include borderRadius(md);
}
@media #{$stena-large-and-up} {
  @include borderRadius(lg);
}