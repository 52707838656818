
                  @import "@@/design-tokens/all.scss";
                
.editorAnchor {
  position: absolute;
  top: 0px;
  left: 0px;
  color: black;
  background-color: white;
  border: 2px solid red;
  padding: 3px;
}

.editorAnchor_container {
  position: relative;
}