
                  @import "@@/design-tokens/all.scss";
                
.breadcrumb_list {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  gap: 8px;
}

.breadcrumb_item {
  list-style: none;
  align-items: center;
  display: flex;
  margin: 0;
  .breadcrumb_link {
    color: $primary-grey-tint-01;
    max-width: calc(100vw - 80px);

    &:hover {
      opacity: 0.9;
      text-decoration: underline;
    }
  }
  .breadcrumb_current {
    color: $complementary-charcoal-tint-03;
    text-transform: uppercase;
  }

  &:last-child {
    @media #{$stena-small-and-down} {
      display: none;
    }
  }
}

.breadcrumb_text {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  margin-left: 8px;
  margin-bottom: 2px;
  color: $primary-grey-tint-02;
  height: 10px;
}
