
                  @import "@@/design-tokens/all.scss";
                
.hbd-scroller {
  --scroller-vertical-spacing: 12px; // Creating space outside overflow, ie for :focus/:hover/box-shadow
  &__container {
    contain: content;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    margin: calc(-1 * var(--scroller-vertical-spacing)) 0;
    position: relative;
    list-style: none;
    padding: var(--scroller-vertical-spacing) 0;
    overflow-x: auto;
    overflow-y: hidden;
    transition: opacity 0.2s;
    opacity: 1;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    will-change: scroll-position;

    gap: 40px;
    @media #{$stena-medium-and-down} {
      column-gap: 24px;
    }
    // gap: 12px;
    // @media #{$stena-medium-and-up} {
    //   gap: 20px;
    // }
    // @media #{$stena-large-and-up} {
    //   gap: 24px;
    // }

    .hbd-scroller--full-width-items & {
      display: flex;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &--smooth {
      scroll-behavior: smooth;
    }
    &--hidden {
      opacity: 0;
      scroll-behavior: unset;
    }
    &--center {
      justify-content: center;
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;

    // Space outside overflow, ie for :focus/:hover/box-shadow
    padding: var(--scroller-vertical-spacing) 0;
    margin: calc(-1 * var(--scroller-vertical-spacing)) 0;

    // Wrapper stretches full-width of the screen
    &--stretch {
      --scroller-wrapper-stretch: calc(-50vw + (var(--scroller-width) / 2));
      margin-left: var(--scroller-wrapper-stretch);
      margin-right: var(--scroller-wrapper-stretch);
      max-width: 100vw;
    }
  }
  &:focus {
    outline: none;
  }
}
