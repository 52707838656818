
                  @import "@@/design-tokens/all.scss";
                
.container {
  background: linear-gradient(180deg, #efefef 0%, rgba(239, 239, 239, 0) 100%);
  // overflow-x: hidden;
}

.scroller_wrapper{
  max-width:100%;
}

.scroller_item {
  width: 560px;

  @media #{$stena-large-and-down} {
    width: 332px;
  }
  @media #{$stena-small-and-down} {
    width: 320px;
  }
}

.scroller_loading {
  width: 560px;
  height: 511px;
  @media #{$stena-large-and-down} {
    width: 332px;
    height: 460px;
  }
  @media #{$stena-small-and-down} {
    width: 320px;
    height: 530px;
  }
}
