//BASE COLORS

$black: #000000;
$white: #ffffff;

// PRIMARY COLORS
$primary-green: #657b6c;
$primary-grey: #62636a;
$primary-blue: #5a6c81;
//PRIMARY TINTS
$primary-green-tint-01: #93a498;
$primary-green-tint-02: #bfcac2;
$primary-green-tint-03: #e5ebe6;
$primary-green-tint-04: #f2f5f3;
$primary-grey-tint-01: #8c8a8f;
$primary-grey-tint-02: #c7c5c9;
$primary-grey-tint-03: #efefef;
$primary-blue-tint-01: #7c95ab;
$primary-blue-tint-02: #b2bfcc;
$primary-blue-tint-03: #e5eaf0;
$primary-blue-tint-04: #f2f4f7;

//COMPLEMENTARY COLORS
$complementary-orange: #a46351;
$complementary-beige: #c5baac;
$complementary-charcoal: #3f4347;
$complementary-blue: #327ea5;

//COMPLEMENTARY TINTS´
$complementary-orange-tint-01: #c18f81;
$complementary-orange-tint-02: #d1ada3;
$complementary-orange-tint-03: #e2cbc5;
$complementary-orange-tint-04: #ebdad6;
$complementary-beige-tint-01: #dbd3c8;
$complementary-beige-tint-02: #ebe7e0;
$complementary-beige-tint-03: #f2f1ed;
$complementary-beige-tint-04: #f7f5f3;
$complementary-charcoal-tint-01: #565c61;
$complementary-charcoal-tint-02: #6e757c;
$complementary-charcoal-tint-03: #888f95;
$complementary-charcoal-tint-04: #c9cccf;
$complementary-blue-tint-01: #62abd0;
$complementary-blue-tint-02: #89c0dc;
$complementary-blue-tint-03: #b1d5e7;
$complementary-blue-tint-04: #d8eaf3;

//SHADES
$shades-blue-01: #478bae;
$shades-blue-02: #286584;
$shades-green-01: #5f7466;
$shades-green-02: #55675a;
$shades-charcoal-01: #3f4347;
$shades-charcoal-02: #323639;
$shades-warning-01: #E5DFC4;

// SEMANTIC COLORS
$info: #327ea5;
$success: #98b347;
$warning: #e0b400;
$danger: #de5c45;
// SEMANTIC TINTS
$info-tint: #e4f4fb;
$success-tint: #e8f3d3;
$warning-tint: #fef4c8;
$danger-tint: #fff0eb;

//CHARTS AND DATA COLORS
$chart-01: #c68d6c;
$chart-02: #98b347;
$chart-03: #327ea5;
