
                  @import "@@/design-tokens/all.scss";
                
.media {
    overflow: hidden;
    border-radius: 5px;
    &__fullwidth {
        border-radius: 0;
    }

    &__landscape {
        @media #{$stena-small-and-down} {
            border-radius: 0;
        }
    }

    &__narrow {
        width: 45%;
        @media #{$stena-medium-and-down} {
            width: 70%;
        }
        @media #{$stena-small-and-down} {
            width: 100%;
        }
    }
   
}

.mediagallery {
    display: grid;
    
    &__large {
        grid-template-columns: repeat(2, 1fr);
        @media #{$stena-small-and-down} {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__medium {
        grid-template-columns: repeat(3, 1fr);
        @media #{$stena-small-and-down} {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__small {
        grid-template-columns: repeat(4, 1fr);
        @media #{$stena-small-and-down} {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        overflow: hidden;
        width:100%;
        border-radius: 5px;

        @media #{$stena-medium-and-up} {
            &__even {
                grid-column-start: 1;
            }
        }
    }
}

