
                  @import "@@/design-tokens/all.scss";
                
.card {
  max-width: 760px;
  text-decoration: none;
  color: $complementary-charcoal-tint-03;
  background-color: $white;
  width: 100%;
  position: relative;

  &_image {
    // height: 380px;
    img {
      height: 100%;
    }
  }

  h5 {
    max-width: 560px;
  }

  &_white {
    background-color: $complementary-beige-tint-03;
  }
  &_beige {
    background-color: $white;
  }
}

.cardimage_wrapper {
  position: relative;
}

.categories_wrapper {
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  width: 100%;
}

.ellipse {
  > :first-child {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media #{$stena-small-and-down} {
      -webkit-line-clamp: 4;
    }
  }
}

.full_height {
  height: 100%;
}

.skeleton_image {
  &_latestnews {
    height: 380px;
    @media #{$stena-medium-and-down} {
      height: 320px;
    }
  }
  &_newsmodule {
    height: 280px;
  }
}

.skeleton_date {
  height: 16px;
  width: 80px;
}

.skeleton_intro {
  height: 64px;
}
