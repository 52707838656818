
                  @import "@@/design-tokens/all.scss";
                


.cards_grid {
display: grid;
grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media #{$stena-medium-and-down} {
grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;   
 *:nth-child(2n-1):nth-last-of-type(1) {
        grid-column: span 2;
        :first-child {
          @media #{$stena-medium-and-up} {
            align-items: center;
          }
        }
      }
    }
    @media #{$stena-small-and-down} {
     display: flex;
     flex-direction: column;
     margin: 8px 0 8px 0;
    }
}


.grid {
  display: grid;
grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
    @media #{$stena-medium-and-down} {

      grid-gap: 16px;
    }
    @media #{$stena-small-and-down} {
      
      grid-template-columns: repeat(1, 100%);
    }
}

.contact_card_icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__center {
    align-items: center;
    text-align: center;

    @media #{$stena-small-and-down} {
      align-items: flex-start;
      text-align: left;
    }
  }
}

