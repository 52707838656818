
                  @import "@@/design-tokens/all.scss";
                
.link_card {
  text-decoration: none;
  text-align: center;
  height: 100%;


  picture {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &_image {
    background-color:$primary-grey-tint-03;
  }

  &_color {
    height: 100%;
    min-height: 282px;
    @media #{$stena-small-and-down} {
      min-height: 250px;
    }

    justify-content: center;
    color: $white;
  }
}
