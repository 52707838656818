
                  @import "@@/design-tokens/all.scss";
                
$animation-time: 0.3s;

.wrapper {
    position: relative;
    height: 100%;
}

.wrapper_inner {
    max-height: 1000px;
    z-index: 10;
    overflow-y: hidden;
    transition: transform 0.1s ease-in-out;
    transform: scaleY(0);
    position: absolute;
    right: 0;
    background: $white;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
    &__header {
        top: 15px;
        transform-origin: 100% 0;
    }
    &__footer{
        bottom: 60px;
        transform-origin: 0 100%;
        @media #{$stena-medium-and-down} {
            bottom: 35px;
        }
    }
    &__open {
        transform: scaleY(1);
    }
}

.list {
    width: fit-content;
    min-width: 200px;
    list-style-type: none;
    padding: 12px 0;
    margin: 0;
    li {
        div, a > * {
            padding: 12px 24px;
        }

        a {
            > div:hover {
                color: $complementary-blue;
            }
        }
    }
}

.icon {
    transition: transform 300ms ease-in-out;
    margin-left: 12px;
    &__footer {
        transform: rotate(180deg);
        &__open {
            transform: rotate(0deg);
        }
    }
    &__header {
        transform: rotate(0deg);
        &__open {
            transform: rotate(180deg);
        }
    }
}