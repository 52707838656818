
                  @import "@@/design-tokens/all.scss";
                
.filter_wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 28px;

  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}



/* justifyContent="space-between"
gap={[5, 7, 7]}
flexDirection={["column", "column", "row"]} */