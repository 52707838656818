
                  @import "@@/design-tokens/all.scss";
                
@keyframes opacityOverlay {
  0% {
    background: transparent;
  }
  100% {
    background: rgba(0, 0, 0, 0.6);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(-8000px) translateX(-50%);
  }
  100% {
    transform: scale(1) translateY(-50%) translateX(-50%);
  }
}

.overlay {
  z-index: 0;
  top: 0;
  left: 0;
  position: fixed;
  background: #3f4347;
  visibility: hidden;
  z-index: -500;
  &.open {
    width: 100vw;
    height: 100vh;
    z-index: 600;
    animation: opacityOverlay 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    visibility: visible;
  }
}
.modal {
  position: fixed;
  width: 95%;
  max-width: 877px;
  max-height: 830px;
  background: white;
  visibility: hidden;
  z-index: -400;

  left: 0;
  top: 0;
  &.open {
    top: 50vh;
    left: 50vw;
    z-index: 700;
    visibility: visible;
    animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  & :global(button) {
    transition: none;
  }
}

.logo {
  height: 40px;
}

.line {
  margin: auto;
  margin-top: 8px;
}

.list {
  margin: 0;
  padding: 0;
  max-height: 512px;
  width: 100%;
  overflow-y: scroll;
  z-index: 1;
}
.market_gradient {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 90%;
  height: 65px;
  z-index: 3;
  bottom: 65px;
  filter: blur(5px);
}

.list_item {
  list-style: none;
  text-align: center;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item:hover {
  color: $complementary-blue;
}

.active_item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-grey-tint-02;
}

.icon_container {
  width: 16px;
  height: 16px;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 12px;
  margin-left: -28px;
}
