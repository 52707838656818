
                  @import "@@/design-tokens/all.scss";
                
.tagcontainer {
  position: relative;
  width: 100%;
  &.showallTags {
    .tag--hidden {
      visibility: visible;
    }
  }
  &__padding {
    padding-right: 50px;
  }
  &__showmore {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    height: 32px;
    width: 32px;
    cursor: pointer;
    background-color: $primary-green-tint-04;
    color: $primary-green;
    border: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    position: absolute;
    right: 8px;
    bottom: 6px;

    &__text {
      font-family: "Stena sans";
      display: block;
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 1.3px;
      font-weight: bold;
      text-transform: uppercase;
      top: 1px;
      position: relative;
    }
    > div {
      position: relative;
      top: 1px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
}

.tagcontainer__tags {
  min-width: 0;
  width: 100%;
}

.hidden_tags {
  width: calc(100% - 32px);
}
.tagcontainer__tagscont {
  overflow: hidden;
}

.tag {
  border-top-right-radius: 5px;
  background-color: $primary-green-tint-01;
  color: $white;
  flex-shrink: 0;
  min-width: 0;
  padding: 5px 12px;
  width: min-content;
  white-space: nowrap;
  max-width: 100%;
  &--hidden {
    visibility: hidden;
  }
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2px;
    margin-bottom: -2px;
  }
}
