
                  @import "@@/design-tokens/all.scss";
                
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_wrapper {
  max-width: 660px;
}
.card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr auto 1fr;
  max-width: 560px;
  text-align: center;
  flex: 1;

  a {
    color: $complementary-blue;
    text-decoration: none;
    transition: all 0.3s ease;
    span {
      word-break: break-word;
    }
  }

  a:hover {
    opacity: 0.9;
    text-decoration: underline;
  }
}

.name_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name_width {
    max-width: 380px;
  }
}

.cards_flex {
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 100%;

  @media #{$stena-small-and-down} {
    flex-direction: column;
    gap: 20px;
  }
}

.phonenumber {
  color: $complementary-charcoal !important;

  &:hover {
    color: $complementary-charcoal !important;
    text-decoration: none !important;
  }
}

.cards_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 40px;
  width: 100%;

  @media #{$stena-medium-and-down} {
    grid-template-columns: 1fr 1fr;
  }

  @media #{$stena-small-and-down} {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
