
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
  a {
    color: inherit;
    &:hover {
      color: $info;
    }
    text-decoration: none;
    outline: none;
    &:focus-visible {
      > div:first-child {
        outline: solid;
      }
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  outline: none;
}

.intro {
  max-width: 100%;
}

.image_wrapper {
  width: 30%;
  max-width: 200px;
  min-width: 133px;
  img {
    border-radius: 5px;
  }
}

.icon {
  background: linear-gradient(180deg, #efefef 0%, rgba(239, 239, 239, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: $primary-grey-tint-02;
    max-height: 74px;
    margin: 30px;
  }

  &_pdf {
    svg {
      width: 50px;
    }
  }

  &_job {
    svg {
      width: 65px;
    }
  }
}

.image_wrapper,
.icon {
  @media #{$stena-small-and-down} {
    display: none;
  }
}

.total_matches {
  display: inline-block;
}

.text_wrapper {
  em {
    font-style: normal;
    background-color: $primary-blue-tint-03;
    color: inherit;
    padding: 2px;
  }
}

.suggestion {
  color: $info;
  &:hover {
    text-decoration: underline;
  }
}
