
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;

    @media #{$stena-medium-and-down} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    @media #{$stena-small-and-down} {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.card {
  background: $white;
  color: $primary-grey;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  img {
    border-radius: 5px 5px 0 0;
  }
  
  &:hover {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  }
}