
                  @import "@@/design-tokens/all.scss";
                
.container_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin: 0;
  padding: 24px;
}
.border {
  border-radius: 5px;
}

.sidebar_card {
  width: 100%;
}

.scroller_item {
  align-items: stretch;
  width: 360px;

  @media #{$stena-small-and-down} {
    width: 100%;
  }
}
