
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
    position: relative;
}

.zoom_container {
    all: unset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    .zoom_wrapper {
      position: relative;
      display: flex;
      align-items: end;
      justify-content: end;
    }
    .zoom_icon {
      border: none;
      opacity: 0.8;
      background-color: $primary-grey-tint-03;
      color: $complementary-blue;
      border-radius: 100%;
      width: 32px;
      height: 32px;
      padding: 4px;
      transition: all 0.3s ease;
      margin-right: 10px;
      margin-bottom: 10px;
  
      cursor: pointer;
    }
  
    &:hover {
      .zoom_icon {
        opacity: 1;
      }
    }
  }