
                  @import "@@/design-tokens/all.scss";
                
.link {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    width: fit-content;
    :hover {
        text-decoration: underline;
    }
    div {
        line-height: 1 !important;
    }
}

.header {
    height: 15px;
    @media #{$stena-small-and-down} {
        height: revert;
    }
}

.category {
    background: $primary-green-tint-04;
    color: $primary-green;
    width: fit-content;
    padding: 4px 12px 2px;
    border-radius: 0px 5px 0px 0px;
}

.column_wrapper {
    flex: 1;
    max-width: 360px;
}