
                  @import "@@/design-tokens/all.scss";
                
.cards_wrapper {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media #{$stena-medium-and-down} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.card {
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  }

  .link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.divider {
  height: 1px;
  width: 52px;
  background: $primary-grey;
  margin: 10px 0 12px;
}

.title_height {
  min-height: 46px;
  @media #{$stena-medium-and-down} {
    min-height: 36px;
  }
  @media #{$stena-small-and-down} {
    min-height: none;
  }
}
