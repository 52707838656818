
                  @import "@@/design-tokens/all.scss";
                
.article {
  width: 100%;
  display: flex;
  color: $complementary-charcoal;

  @media #{$stena-large-and-up} {
    gap: 40px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    /* > *:nth-child(2) {
      padding: 20px 0;
    } */
  }

  &_right-small,
  &_right {
    flex-direction: row-reverse;
  }

  &_left-small,
  &_right-small,
  &_left,
  &_right {
    @media #{$stena-medium-and-down} {
      flex-direction: column;
    }

    @media #{$stena-small-and-down} {
      width: 100%;
      align-items: center;
    }

    .img_container {
      width: 50%;

      &__small {
        width: 360px;
        @media #{$stena-small-and-down} {
          padding: 0 22px;
        }
      }

      @media #{$stena-medium-and-down} {
        width: 100%;
      }
    }
    .text_container {
      width: 50%;
      @media #{$stena-medium-and-down} {
        width: 100%;
        padding-top: 40px;
        padding-left: 0;
      }
      @media #{$stena-small-and-down} {
        background-color: $white;
        padding: 12px;
        padding-top: 24px;
        width: calc(100% - 20px);
        border-radius: 5px;
        margin-top: -56px;
        z-index: 0;
        margin-left: 10px;
        margin-right: 10px;
      }

      &__small {
        flex: 1;
        @media #{$stena-small-and-down} {
          margin-top: 0;
        }
      }
    }
    .hasvideo {
      @media #{$stena-small-and-down} {
        margin-top: 0px;
      }
    }
  }

  &_full {
    flex-direction: column;
    .img-container {
      width: 100%;

      @media #{$stena-medium-and-down} {
        border-radius: 0;
      }
    }

    .text_container {
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 1fr;

      &__wide {
        @media #{$stena-large-and-up} {
          display: flex;
          max-width: 744px;
          gap: 0;
        }
      }

      @media #{$stena-medium-and-down} {
        grid-template-columns: auto;
        grid-gap: 0;
        padding: 40px 20px 0 20px;
        width: 80%;
      }

      @media #{$stena-small-and-down} {
        width: 100%;
        padding-top: 24px;
      }
    }
  }
}

.button_container {
  display: flex;
  width: 100%;

  @media #{$stena-small-and-down} {
    flex-direction: column;
  }

  > *:first-child {
    margin-right: 20px;

    @media #{$stena-small-and-down} {
      margin: 0 0 20px 0;
    }
  }
}
