
                  @import "@@/design-tokens/all.scss";
                

.cta_full {
  width: 100%;

  .button_container, .button_container a {
    width: 100%;
    max-width: 100%;
  }
}

.sticky_button {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.05);

  @media #{$stena-small-and-down} {
    display: block;
  }
}
.hidden {
  display: none;
}