
                  @import "@@/design-tokens/all.scss";
                
.dropdown_wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media #{$stena-medium-and-down} {
    gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  @media #{$stena-small-and-down} {
    gap: 12px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  > div {
    max-width: 500px;
  } 
}