
                  @import "@@/design-tokens/all.scss";
                
.reset_button {
    background: none;
    color: $complementary-blue;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    div {
      margin-top: 2px;
    }
  
    svg {
      $icon-size: 12px;
      min-width: $icon-size;
      max-width: $icon-size;
      min-height: $icon-size;
      max-height: $icon-size;
    }
  
    &:hover {
      color: $complementary-blue-tint-02;
    }
  
    &:disabled {
      color: $primary-grey-tint-02;
      cursor: unset;
    }
  }