
                  @import "@@/design-tokens/all.scss";
                
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notch {
    margin-top: auto;
    > div {
        height: 50px;
        width: 100%;
        max-width: 1208px;
        background: $white;
        border-radius: 5px 5px 0 0;
        @media #{$stena-small-and-down} {
            height: 40px;
        }
    }
}