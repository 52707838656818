
                  @import "@@/design-tokens/all.scss";
                
$hui-segment-color: $stena-color-complementary-charcoal-tint04 !default;
$hui-segment-active-color: $stena-color-complementary-charcoal-tint01 !default;

.hbd-scroller {
  &__segments {
    margin: 0;
    display: flex;
    list-style: none;
    width: 100%;
    padding: 0;
  }

  &__segmentcontainer {
    overflow: hidden;
  }
  
  &__segment {
    padding: 0 4px;
    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
  }

  &__segment-button {
    position: relative;
    outline: none;
    height: 20px;
    border: none;
    background: none;
    width: 100%;
    cursor: pointer;

    &:after {
      content: "";
      height: 2px;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      transition: background-color .2s;
      background-color: $hui-segment-color;
    }

    &--contrast {
      &:after {
        background-color: rgba($stena-color-white, 0.25);
      }
      &.hbd-scroller__segment-button--active,
      &.hbd-scroller__segment-button--partial {
        &:after {
          background-color: var(--ui-color-white);
        }
      }
    }

    &--active:after,
    &--partial:after {
      background-color: $hui-segment-active-color;
    }
  }
}