
                  @import "@@/design-tokens/all.scss";
                
$items-per-row: 7;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 16px;
  @media #{$stena-small-and-down} {
    flex-direction: column;
  }
  &__center {
    justify-content: center;
  }
}

.item {
  max-width: calc(1 / 3 * 100% - (1 - 1 / 3) * 16px);
  flex: 1;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  gap: 12px;
  padding: 0 8px;
  border-radius: 5px;
  color: $complementary-blue;
  background-color: $white;
  border: 1px solid $primary-grey-tint-02;
  text-decoration: none;

  @media #{$stena-medium-and-down} {
    max-width: calc(1 / 2 * 100% - (1 - 1 / 2) * 16px);
  }

  &__overflow {
    max-width: calc(1 / 7 * 100% - (1 - 1 / 7) * 16px);
    min-width: 152px;

    @media #{$stena-medium-and-down} {
      max-width: calc(1 / 4 * 100% - (1 - 1 / 4) * 16px);
      min-width: 158px;
    }
  }

  @media #{$stena-small-and-down} {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    min-height: 0;
    padding: 12px 16px;
    position: relative;
  }

  &__current_no_icon {
    justify-content: center;
  }

  &__has_icon {
    min-height: 100px;
    padding: 10px;
    @media #{$stena-small-and-down} {
      min-height: 0;
      padding: 12px 42px;
      justify-content: center;
    }
  }

  &:hover {
    color: $complementary-charcoal-tint-03;
  }
  &__active {
    color: $complementary-charcoal;
    background-color: $primary-blue-tint-03;
    border: 1px solid transparent;
    &:hover {
      color: $primary-blue-tint-01;
    }
  }

  &__text {
    font-size: 12px !important;
  }
}

.text_wrapper {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  a {
    color: $complementary-blue;
    &:hover {
      text-decoration: underline;
    }
  }
}

.icon {
  width: 32px;
  @media #{$stena-small-and-down} {
    position: absolute;
    left: 16px;
    max-width: 24px;
  }
}

.show_all_link {
  background-color: $primary-grey-tint-03;
  color: $complementary-blue;
  border: 1px solid transparent;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 32px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: $primary-blue-tint-01;
  }
}
