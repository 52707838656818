
                  @import "@@/design-tokens/all.scss";
                
.videoplayer{
  aspect-ratio: 16 / 9; 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $black;
  position: relative;

  iframe {
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%; 
    }
    
}