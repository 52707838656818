
                  @import "@@/design-tokens/all.scss";
                
.hui-box {
  &--full-height {
    height: 100%;
  }
  &--full-width {
    width: 100%;
  }
  &--no {
    overflow: hidden;
  }
  &--mhz {
    min-height: 0;
  }
  &--pr {
    position: relative;
  }
  &--btn {
    cursor: pointer;
  }
}