
                  @import "@@/design-tokens/all.scss";
                
// @import '/src/styles/index.scss';

//$spacings: (4,8,16,24,32,40,48,56,64,72,80,112);
//$grid-spacing-values: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
$grid-size-values: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@mixin gridSpacing {
  @each $v in $spacings {
    .snw-grid__spacing-#{$v} {
      $mp: #{$v * 2};
      $w: #{$v * 4};

      margin: -#{$mp}px;
      width: calc(100% + #{$w}px);
      & > .snw-grid__item {
        padding: #{$mp}px
      }
    }
  }
}

@mixin gridSize($breakpoint) {
  .snw-grid__grid-#{$breakpoint}-auto {
    flex-basis: auto;
    flex-grow: 0;
    max-width: none
  }
 .snw-grid__grid-#{$breakpoint}-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }
  @each $v in $grid-size-values {
    .snw-grid__grid-#{$breakpoint}-#{$v} {
      $p: percentage(calc(100 / 12) * calc($v / 100));

      flex-basis: #{$p};
      max-width: #{$p};
      flex-grow: 0;
    }
  }
}

.snw-grid {
  &__container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%
  }

  &__full-height {
    height: 100%
  }

  &__item {
    box-sizing: border-box;
    margin: 0;
    flex-grow: 1;
  }

  &__zero-min-width {
    min-width: 0
  }

  &__direction-row {
    flex-direction: row
  }

  &__direction-column {
    flex-direction: column
  }
  
  &__direction-column-reverse {
    flex-direction: column-reverse
  }

  &__direction-row-reverse {
    flex-direction: row-reverse
  }

  &__wrap-wrap {
    flex-wrap: wrap
  }

  &__wrap-nowrap {
    flex-wrap: nowrap
  }

  &__wrap-wrap-reverse {
    flex-wrap: wrap-reverse
  }

  &__align-items-stretch {
    align-items: stretch
  }

  &__align-items-center {
    align-items: center
  }

  &__align-items-flex-start {
    align-items: flex-start
  }

  &__align-items-flex-end {
    align-items: flex-end
  }

  &__align-items-baseline {
    align-items: baseline
  }

  &__align-content-stretch {
    align-content: stretch
  }

  &__align-content-center {
    align-content: center
  }

  &__align-content-flex-start {
    align-content: flex-start
  }

  &__align-content-flex-end {
    align-content: flex-end
  }

  &__align-content-space-between {
    align-content: space-between
  }

  &__align-content-space-around {
    align-content: space-around
  }

  &__justify-flex-start {
    justify-content: flex-start
  }

  &__justify-center {
    justify-content: center
  }

  &__justify-flex-end {
    justify-content: flex-end
  }

  &__justify-space-between {
    justify-content: space-between
  }

  &__justify-space-around {
    justify-content: space-around
  }

  &__justify-space-evenly {
    justify-content: space-evenly
  }
}

@include gridSpacing();

@media #{$snw-small-and-up} {
  @include gridSize(sm);
}


@media #{$snw-medium-and-up} {
  @include gridSize(md);
}

@media #{$snw-large-and-up} {
  @include gridSize(lg);
}