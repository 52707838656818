
                  @import "@@/design-tokens/all.scss";
                
.background_bleed {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
  margin-top: -72px;
  @media #{$stena-small-and-down} {
    margin-top: -56px;
  }
}

.content_wrapper {
  max-width: 744px;
}

.link {
  @media #{$stena-small-and-down} {
    display: none;
  }
}