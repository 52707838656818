$snw_fontfamilyname: 'Stena sans';

@import "./breakpoints/index.scss";
@import "./colors/index.scss";

//remove these duplicates
@import "./../styles/breakpoints/breakpoints.scss";
@import "./../styles/colors/colors.scss";
@import "./../styles/spacing/spacing.scss";
@import "../styles/typography/typography";
