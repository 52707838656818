
                  @import "@@/design-tokens/all.scss";
                
.hbd-scroller {
  &__solid-bar {
    @media (pointer: fine) {
      cursor: pointer;
    }
  }
  &__bar-track {
    position: relative;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.04);
  }
  &__bar-current {
    position: absolute;
    top: 0;
    transition: width 100ms;
    background-color: var( --ui-color-complementary-charcoal-tint01);
    height: 2px;
    border-radius: 2px;
    @media (pointer: fine) {
      .hbd-scroller__solid-bar:hover & {
        top: -1px;
        height: 4px;
        border-radius: 4px;
      }
    }
  }
}
