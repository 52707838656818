
                  @import "@@/design-tokens/all.scss";
                
.card {
  border-radius: 5px;
  display: flex;
  align-content: flex-start;
  @media #{$stena-small-and-down} {
    flex-direction: column;
    padding: 20px;
  }

  .text_wrapper {
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    justify-content: stretch;
    flex-grow: 1;
    &_description {
      display: flex;
      flex: 0 0 auto;
    }

    &_information {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    @media #{$stena-small-and-down} {
      margin: 0;
    }
  }
  .phonenumber {

    &:hover {
      color: $primary-grey !important;
      text-decoration: none !important;
    }
  }

  &_large {
      padding: 24px;
      .text_wrapper{
        @media #{$stena-small-and-down} {
         padding-top: 30px;
        }
      }
  }

  &_medium {
    padding: 24px;
    .text_wrapper{
      @media #{$stena-small-and-down} {
       padding-top: 30px;
      }
    }
  }

  &_small {
    padding: 24px;
    flex-direction: column;
    .text_wrapper {
      padding-top: 24px;
      margin: 0;
    }
  }
}

hr.separator {
  margin: auto;
  border: 0;
  border-bottom: 2px solid $primary-grey;
  margin-top: 6px;
  margin-bottom: 12px;
}

.header_wrapper {
  h2,
  p {
    max-width: 820px;
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-gap: 40px;
  justify-content: center;

  @media #{$stena-medium-and-down} {
    grid-gap: 16px;
  }

  &_large {
    grid-template-columns: minmax(auto, 560px);
    @media #{$stena-medium-and-down} {
      grid-template-columns: 1fr;
    }
  }

  &_medium {
    grid-template-columns: repeat(2, 1fr);
    @media #{$stena-medium-and-down} {
      grid-template-columns: 1fr;
    }
  }

  &_small {
    grid-template-columns: repeat(4, 1fr);

    &__3_card {
      grid-template-columns: repeat(3, minmax(auto, 280px));
    }

    @media #{$stena-medium-and-down} {
      grid-template-columns: repeat(3, 1fr);
    }
    @media #{$stena-small-and-down} {
      grid-template-columns: 1fr;
    }
  }

  &_noimage {
    &_large {
      grid-template-columns: minmax(auto, 560px);
    }
  
    &_medium {
      grid-template-columns: repeat(2, 1fr);
      @media #{$stena-small-and-down} {
        grid-template-columns: 1fr;
      }
    }
  
    &_small {
      grid-template-columns: repeat(4, 1fr);

      &__3_card {
        grid-template-columns: repeat(3, minmax(auto, 280px));
      }

      @media #{$stena-medium-and-down} {
        grid-template-columns: repeat(2, 1fr);
      }
      @media #{$stena-small-and-down} {
        grid-template-columns: 1fr;
      }
    }
  }
}

.noimage_card {

  &_large {
    padding: 52px;
    @media #{$stena-medium-and-down} {
      padding:40px;
    }
    @media #{$stena-small-and-down} {
     padding:40px 24px;
    }
  }

  &_medium {
    padding: 40px;
     @media #{$stena-small-and-down} {
     padding:40px 24px;
    }
  }

  &_small {
    padding:40px 24px;
      .text_wrapper {
      margin: 0;
    }
  }
}