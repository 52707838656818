// -----------------------------------------------------
// This file was generated, do not modify this directly.
// change the design token-file and run 'npm run generate:tokens'
// -------------------------------------------------------------------

:root {
  --ui-breakpoint-small: 0;
  --ui-breakpoint-medium: 600;
  --ui-breakpoint-large: 900;
  --ui-breakpoint-extra-large: 1200;
}