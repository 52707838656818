// -----------------------------------------------------
// This file was generated, do not modify this directly.
// change the design token-file and run 'npm run generate:tokens'
// -------------------------------------------------------------------
$stena-color-black: #000000 !default;
$stena-color-white: #ffffff !default;
$stena-color-primary-green: #657b6c !default;
$stena-color-primary-grey: #62636a !default;
$stena-color-primary-blue: #5a6c81 !default;
$stena-color-primary-green-tint01: #93a498 !default;
$stena-color-primary-green-tint02: #bfcac2 !default;
$stena-color-primary-green-tint03: #e5ebe6 !default;
$stena-color-primary-grey-tint01: #8c8a8f !default;
$stena-color-primary-grey-tint02: #c7c5c9 !default;
$stena-color-primary-grey-tint03: #efefef !default;
$stena-color-primary-blue-tint01: #7c95ab !default;
$stena-color-primary-blue-tint02: #b2bfcc !default;
$stena-color-primary-blue-tint03: #e5eaf0 !default;
$stena-color-primary-blue-tint04: #f2f4f7 !default;
$stena-color-complementary-orange: #a46351 !default;
$stena-color-complementary-beige: #c5baac !default;
$stena-color-complementary-charcoal: #3f4347 !default;
$stena-color-complementary-blue: #327ea5 !default;
$stena-color-complementary-orange-tint01: #c18f81 !default;
$stena-color-complementary-orange-tint02: #d1ada3 !default;
$stena-color-complementary-orange-tint03: #e2cbc5 !default;
$stena-color-complementary-orange-tint04: #ebdad6 !default;
$stena-color-complementary-beige-tint01: #dbd3c8 !default;
$stena-color-complementary-beige-tint02: #ebe7e0 !default;
$stena-color-complementary-beige-tint03: #f2f1ed !default;
$stena-color-complementary-beige-tint04: #f7f5f3 !default;
$stena-color-complementary-charcoal-tint01: #565c61 !default;
$stena-color-complementary-charcoal-tint02: #6e757c !default;
$stena-color-complementary-charcoal-tint03: #888f95 !default;
$stena-color-complementary-charcoal-tint04: #c9cccf !default;
$stena-color-complementary-blue-tint01: #62abd0 !default;
$stena-color-complementary-blue-tint02: #89c0dc !default;
$stena-color-complementary-blue-tint03: #b1d5e7 !default;
$stena-color-complementary-blue-tint04: #d8eaf3 !default;
$stena-color-shades-blue01: #478bae !default;
$stena-color-shades-blue02: #286584 !default;
$stena-color-shades-green01: #5f7466 !default;
$stena-color-shades-green02: #55675a !default;
$stena-color-shades-charcoal01: #3f4347 !default;
$stena-color-shades-charcoal02: #323639 !default;
$stena-color-shades-warning01: #E5DFC4 !default;
$stena-color-info: #327ea5 !default;
$stena-color-success: #98b347 !default;
$stena-color-warning: #e0b400 !default;
$stena-color-danger: #de5c45 !default;
$stena-color-info-tint: #e4f4fb !default;
$stena-color-success-tint: #e8f3d3 !default;
$stena-color-warning-tint: #fef4c8 !default;
$stena-color-danger-tint: #fff0eb !default;
$stena-color-chart01: #c68d6c !default;
$stena-color-chart02: #98b347 !default;
$stena-color-chart03: #327ea5 !default;