
                  @import "@@/design-tokens/all.scss";
                
@import "../../../styles/pages/content/index.scss";
$content-max-width: 1208px;

.hero {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__notch_wrapper {
    width: 100%;
  }
  &__notch {
    min-height: 40px;
    max-width: $content-max-width;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $white;
    position: relative;
    bottom: -1px;
  }
}
.breadcrumb_wrapper {
  position: relative;
}

.hero_content {
  &__wrapper {
    @media #{$stena-large-and-up} {
      max-width: calc($sidebar-left-max-width + 40px);
    }
    width: 100%;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__tag {
    color: $white;
    background-color: $primary-green-tint-01;
    padding: 5px 12px;
    border-top-right-radius: 5px;
  }

  &__pretitle {
    display: inline-block;
    position: relative;
  }

  &__br {
    display: inline-block;
    hr {
      margin-left: 0;
      border: 0;
      border-bottom: 2px solid $complementary-charcoal;
     
    }
  }
}


