
                  @import "@@/design-tokens/all.scss";
                
@import "./mixins.scss";

.stena-container {
  --container-max-width: 1160px;
  --container-gutter-x-size: 0px;
  --container-gutter-y-size: 0px;

  &--dgx {
    --container-gutter-x-size: 20px;

    @media #{$stena-medium-and-up} {
      --container-gutter-x-size: 40px;
    }
  }

  &--dgy {
    --container-gutter-y-size: 56px;

    @media #{$stena-medium-and-up} {
      --container-gutter-y-size: 72px;
    }
  }

  &--root {
    position: relative;
  }

  @include size-settings(sm);
  @include gutter-settings(sm);

  @media #{$stena-medium-and-up} {
    @include size-settings(md);
    @include gutter-settings(md);
  }

  @media #{$stena-large-and-up} {
    @include size-settings(lg);
    @include gutter-settings(lg);
  }

  --dubbel-gutter-width: calc(var(--container-gutter-x-size) * 2);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: var(--container-gutter-x-size);
  padding-right: var(--container-gutter-x-size);
  padding-top: var(--container-gutter-y-size);
  padding-bottom: var(--container-gutter-y-size);
  max-width: calc(var(--container-max-width) + var(--dubbel-gutter-width));
}
