
                  @import "@@/design-tokens/all.scss";
                
// ALL CARDS
.card {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__link {
      &:hover { 
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
      }
  }

  &_green {
    background-color: $primary-green;
  }
  &_grey {
    background-color: $primary-grey;
  }
  &_blue {
    background-color: $primary-blue;
  }

  &_orange {
    background-color: $complementary-orange;
  }
  &_beige {
    background-color: $complementary-beige;
  }

  &_greyTint {
    background-color: $primary-grey-tint-01;
  }

  &_greenTint {
    background-color: $primary-green-tint-01;
  }

  &_blueTint {
    background-color: $primary-blue-tint-01;
  }

  &_orangeTint {
    background-color: $complementary-orange-tint-01;
  }

  &_beigeTint {
    background-color: $complementary-beige-tint-01;
  }

  &_edit {
    max-width: 400px;
  }
}

/// PRODUCT CARD STYLES
.product_card {
  height: 282px;
  text-decoration: none;
  text-align: center;

  &:hover {
    box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.12);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_color {
    justify-content: center;
    color: $white;
  }
  &_image {
    justify-content: flex-end;
    color: $primary-grey;
    .header_wrapper {
      background: $primary-grey-tint-03;
      width: 100%;
      text-align: center;
    }
  }
}

.link {
  border: 10px solid black;
  width: 360px;
  height: 282px;

  @media #{$stena-small-and-down} {
    width: 100%;
  }
}

// TEXT CARD

.text_card {
  height: 100%;
  min-height: 380px;
  color: $white;
  text-align: center;
  padding: 32px;
}

.text_link {
  color: $white;
text-decoration: underline;
  :hover {
    opacity: 0.8;
  }
}

//PROCESS CARD
.process_card {
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__svg {
    width: 40%;
  }
}

.outer_circle {
  border-radius: 100%;
  width: 220px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;

  &_green {
    background-color: $primary-green-tint-03;

    > *:first-child {
      background-color: $primary-green-tint-03;
      color: $primary-green;
    }
  }
  &_grey {
    background-color: $primary-grey-tint-03;
    > *:first-child {
      background-color: $primary-grey-tint-03;
      color: $primary-grey;
    }
  }
  &_blue {
    background-color: $primary-blue-tint-03;

    > *:first-child {
      background-color: $primary-blue-tint-03;
      color: $primary-blue;
    }
  }

  &_orange {
    background-color: $complementary-orange-tint-04;

    > *:first-child {
      background-color: $complementary-orange-tint-04;

      svg {
        fill: $complementary-orange;
      }
    }
  }

  &_beige {
    background-color: $complementary-beige-tint-03;

    > *:first-child {
      background-color: $complementary-beige-tint-03;

      svg {
        fill: $complementary-beige;
      }
    }
  }
}

.inner_circle {
  border-radius: 100%;
  width: 200px;
  height: 200px;

  border: 4px solid $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 260px;
  padding: 20px 0;
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: center;

  > *:first-child {
    margin-bottom: 8px;
  }
}
