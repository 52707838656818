
                  @import "@@/design-tokens/all.scss";
                
@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(0, -2%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
.faqItem {
    $animationtime: .5s;
    animation-name: fadeInLeft;
    animation-duration: .9s;
    position: relative;
    border-radius: 5px;
    background-color: $primary-grey-tint-03;
    transition: $animationtime all;
    &__container {
        max-width: 768px;
    }
    &.is-active { 
        background-color: $primary-blue-tint-03;
    }

    &_actionbutton {
        cursor: pointer;
        right: 24px;
        top: 24px;
        margin: 0;
        width: 36px;
        height: 36px;;
        padding: 10px;
        border-radius: 100%;
        background-color: $primary-blue-tint-01;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        flex-shrink: 0;

        & > svg {
            transform: rotate(-180deg);
            transition: $animationtime transform;
            transform-origin: 50% 55%;
            height: 100%;
            width: 100%;
        }

        @media #{$stena-medium-and-down} {
            width: 24px;
            height: 24px;;
            padding: 8px;
        }
    }

    &.is-active &_actionbutton {
        & > svg {
            transform: rotate(0deg);
        }
        background-color: $complementary-blue;
    }
    .is-active &__content{
        //add css if acordion is open
     }
        
    &:hover .faqItem_actionbutton {
        background-color: #89A0B3;
    }
    &.is-active:hover .faqItem_actionbutton {
        background-color: $shades-blue-01;
    }

    &__header {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        
        @media #{$stena-medium-and-up} {
            gap: 142px;
        }
    }

    &__content {
        overflow: hidden;
        transition: $animationtime max-height;
        will-change: max-height;
        max-height: 0px;
    }
    &.white {
        background-color: $white;

        &.is-active { 
            background-color: $white;
        }
    }
    &.grey {
        background-color: $primary-grey-tint-03;

        &.is-active { 
            background-color: $primary-grey-tint-03;
        }
    }
    &.blue {
        background-color: $primary-blue-tint-03;

        &.is-active { 
            background-color: $primary-blue-tint-03;
        }
    }
}