
                  @import "@@/design-tokens/all.scss";
                
:root {
  --dropdown-translate-y: 0px;
  --dropdown-translate-x: 0px;
  --menu-animation-duration: 500ms;
}

:global {
  .main-menu {
    position: relative;
    transform: translateY(0px) scaleY(1);
    height: 100px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    transform-origin: 100% 0;
    will-change: transform;
    z-index: 200;

    .dropdown {
      transform: translateY(var(--dropdown-translate-y))
        translateX(var(--dropdown-translate-x));
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: var(--menu-animation-duration);
      will-change: transform;
    }
  }

  .main-menu.main-menu-hide {
    transform: translateY(-150px) scaleY(0);

    .dropdown {
      transform: translateY(-100%) translateX(var(--dropdown-translate-x));
    }
  }

  .main-menu.main-menu-sticky {
    position: sticky;
    top: 50px;
    @media #{$stena-small-and-down} {
      top: 0px;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .dropdown {
    --menu-animation-duration: 0ms;
  }
}

.list_item {
  list-style: none;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%) translateX(var(--dropdown-translate-x));
  }

  100% {
    transform: translateY(0) translateX(var(--dropdown-translate-x));
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

button {
  cursor: pointer;
}

.content_width {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  padding: 0 40px;
  
  @media #{$stena-medium-and-down} {
    padding: 0 24px;
  }
}

.main_container {
  width: 100%;
  height: 100px;
  background-color: $white;
  color: $complementary-charcoal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.08));
  max-width: 100vw;

  ul {
    flex: 1;
    display: flex;
    max-width: #{$stena-breakpoint-extra-large}px;
    justify-content: space-evenly;
    padding: 0;

    @media #{$stena-medium-and-down} {
      display: none;
    }
  }
}

.flex {
  display: flex;
  .search,
  .hamburger {
    svg {
      height: 25px;
      @media #{$stena-medium-and-down} {
        height: 20px;
      }
    }
  }
}

.logo {
  object-fit: contain;
  height: 44.44px;
  @media #{$stena-small-and-down} {
    height: 40.37px;
  }

  &__small {
    height: 24.44px;
    @media #{$stena-small-and-down} {
      height: 22.2px;
    }
  }

  img {
    height: 100%;
  }
}
.button {
  border: none;
  background: none;
  color: $complementary-charcoal;
  transition: all ease 0.2s;
  cursor: pointer;
  &_current {
    color: $primary-grey-tint-01;
  }
}

.button:hover {
  color: $complementary-blue;
}

.search {
  border: none;
  background: none;
  cursor: pointer;
  color: $complementary-charcoal;
  padding: 0;
  &:hover {
    @media (hover: hover) and (pointer: fine) {
      color: $primary-grey-tint-01;
    }
  }
}

.mobile_link {
  display: none;
  border: none;
  background: none;
  padding: 0;
  margin-right: 20px;
  cursor: pointer;
  color: $stena-color-complementary-charcoal;
  
  &:hover {
    @media (hover: hover) and (pointer: fine) {
      color: $primary-grey-tint-01;
    }
  }

  @media #{$stena-small-and-down} {
    display: block;
  }
}

.hamburger {
  display: none;
  border: none;
  background: none;
  padding: 0;
  margin-left: 28px;
  cursor: pointer;

  svg {
    color: $complementary-charcoal;
    height: 20px;
  }
  @media #{$stena-medium-and-down} {
    display: block;
  }
  @media #{$stena-small-and-down} {
    margin-left: 20px;
  }
}

.dropdown {
  display: flex;
  position: absolute;
  background: $white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  width: fit-content;
  //animation: slideDown cubic-bezier(0.4, 0, 0.2, 1) 500ms;
  transition: transform cubic-bezier(0.4, 0, 0.2, 1) var(--menu-animation-duration);
  transform-origin: top;
  @media #{$stena-medium-and-down} {
    display: none;
  }

  &.closed {
    transform: translateY(-100px);
  }
}
.current_page {
  > *:first-child {
    font-weight: bold;
  }
}
.menu_items {
  background: none;
  border: none;
  color: $complementary-charcoal;
  text-decoration: none;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  text-align: start;
  padding: 8px 15px 8px 40px;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  transition: all ease 500ms;

  svg {
    height: 16px;
  }

  &_submenu1 {
    margin-bottom: 2px;
  }

  @media #{$stena-medium-and-down} {
    padding: 8px 30px 8px 30px;

    &_submenu1 {
      padding: 12px 30px 12px 30px;
    }
  }

  &:hover,
  &_active {
    color: $primary-grey-tint-01;
  }

  &_recycling:hover,
  &_active_recycling {
    background-color: rgba($primary-green-tint-03, 0.5);
  }

  &_metall:hover,
  &_active_metall {
    background-color: rgba($primary-blue-tint-03, 0.5);
  }

  &_steel:hover,
  &_active_steel {
    background-color: rgba($primary-grey-tint-03, 0.5);
  }

  &_oil:hover,
  &_active_oil {
    background-color: rgba($complementary-beige-tint-02, 0.5);
  }
  &_aluminium:hover,
  &_active_aluminium {
    background-color: rgba($primary-grey-tint-03, 0.5);
  }
}

.full {
  min-width: 630px;
  z-index: -1;
}

.half {
  min-width: 315px;
  z-index: -1;
  .submenu {
    width: 100%;
  }
}

.drawer {
  z-index: 1000;
}
.drawer1 {
  z-index: 1001;
}

.listing_item {
  white-space: nowrap;
  background: none;
  border-bottom: 1px solid;
  text-decoration: none;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  text-align: start;
  padding-bottom: 4px;
  margin-bottom: 8px;
  display: flex;
  width: calc(100% - 80px);

  svg {
    height: 16px;
  }
  @media #{$stena-medium-and-down} {
    align-self: center;
    padding-top: 20px;
    width: calc(100% - 60px);
    margin-bottom: 12px;
  }

  &_recycling {
    color: $primary-green !important;
    border-color: $primary-green-tint-02;

    svg {
      fill: $primary-green-tint-01;
    }
  }

  &_metall {
    color: $primary-blue !important;
    border-color: $primary-blue-tint-02;
    svg {
      fill: $primary-blue-tint-01;
    }
  }

  &_steel {
    color: $primary-grey !important;
    border-color: $primary-grey-tint-02;
    svg {
      fill: $primary-grey-tint-01;
    }
  }

  &_oil {
    color: $primary-grey !important;
    border-color: $primary-grey-tint-02;
    svg {
      fill: $primary-grey-tint-01;
    }
  }
  &_aluminium {
    color: $primary-grey !important;
    border-color: $primary-grey-tint-02;
    svg {
      fill: $primary-grey-tint-01;
    }
  }
}

.submenu {
  width: 50%;
  padding: 30px 0px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    height: 10px;
  }

  &_recycling {
    background-color: rgba($primary-green-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-green-tint-03;
    }
  }

  &_metall {
    background-color: rgba($primary-blue-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-blue-tint-03;
    }
  }

  &_steel {
    background-color: rgba($primary-grey-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-grey-tint-03;
    }
  }

  &_oil {
    background-color: rgba($complementary-beige-tint-02, 0.5);

    .menu_items:hover {
      background-color: $complementary-beige-tint-02;
    }
  }
  &_aluminium {
    background-color: rgba($primary-grey-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-grey-tint-03;
    }
  }
}

.overlay {
  display: none;
  @media #{$stena-medium-and-down} {
    display: block;
    position: fixed;
    top: 0;
    z-index: 201;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
}

.drawer {
  display: none;
  @media #{$stena-medium-and-down} {
    position: absolute;
    height: 100dvh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 0px;
    z-index: 300;
    top: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
    transition-property: width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 800ms;
    will-change: transform;
    transform-origin: top right;

    .menu_items {
      opacity: 0;
      width: 80vw;
    }
    @media #{$stena-small-and-down} {
      transition-duration: 900ms;
      top: 0;
    }
    &_opened {
      width: 80vw;
      .menu_items {
        opacity: 1;
      }
    }
  }
}

.icon_container {
  padding: 16px;
  display: flex;
  justify-content: space-between;

  svg {
    color: $complementary-charcoal;
    height: 16px;
    width: 17px;
  }
  button {
    background: none;
    border: none;
    padding: 0;
  }
}

.mobile_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  list-style-type: none;

  svg {
    height: 10px;
  }
}

.accordion_list {
  padding: 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  list-style-type: none;

  svg {
    height: 10px;
  }
}

.chevron_transform {
  svg {
    transform: rotate(90deg);
    transition: transform 300ms ease-in-out;
  }
}

.chevron_transform_open {
  svg {
    transform: rotate(-90deg);
  }
}
.accordion {
  width: 100%;
}

.accordion_title {
  &_recycling {
    background-color: $primary-green-tint-03;
  }

  &_metall {
    background-color: $primary-blue-tint-03;
  }

  &_steel {
    background-color: $primary-grey-tint-03;
  }

  &_oil {
    background-color: $complementary-beige-tint-02;
  }
  &_aluminium {
    background-color: $primary-grey-tint-03;
  }
}

.accordion_content {
  animation: growDown 300ms;
  transform-origin: top;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
  }
  &_recycling {
    background-color: rgba($primary-green-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-green-tint-03;
    }
  }

  &_metall {
    background-color: rgba($primary-blue-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-blue-tint-03;
    }
  }

  &_steel {
    background-color: rgba($primary-grey-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-grey-tint-03;
    }
  }

  &_oil {
    background-color: rgba($complementary-beige-tint-02, 0.5);

    .menu_items:hover {
      background-color: $complementary-beige-tint-02;
    }
  }
  &_aluminium {
    background-color: rgba($primary-grey-tint-03, 0.5);

    .menu_items:hover {
      background-color: $primary-grey-tint-03;
    }
  }
}

.hidden {
  display: none;
}

.market_container {
  list-style: none;
  margin: 0;
  padding: 30px 0;
  width: 80vw;
}

.active_item {
  &_recycling {
    background-color: rgba($primary-green-tint-03, 0.5);
  }

  &_metall {
    background-color: rgba($primary-blue-tint-03, 0.5);
  }

  &_steel {
    background-color: rgba($primary-grey-tint-03, 0.5);
  }

  &_oil {
    background-color: rgba($complementary-beige-tint-02, 0.5);
  }
  &_aluminium {
    background-color: rgba($primary-grey-tint-03, 0.5);
  }

  padding: 12px 30px;
  margin-bottom: 2px;
  a {
    color: $primary-grey-tint-01;
  }
}

.market_item {
  padding: 12px 30px;
  margin-bottom: 2px;
  a {
    color: $complementary-charcoal;
  }

  &_recycling:hover {
    background-color: rgba($primary-green-tint-03, 0.5);
  }
  &_metall:hover {
    background-color: rgba($primary-blue-tint-03, 0.5);
  }

  &_steel:hover {
    background-color: rgba($primary-grey-tint-03, 0.5);
  }

  &_oil:hover {
    background-color: rgba($complementary-beige-tint-02, 0.5);
  }

  &_aluminium:hover {
    background-color: rbga($primary-grey-tint-03, 0.5);
  }
}
