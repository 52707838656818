
                @import "@@/design-tokens/all.scss";
                
@import "reset";
@import "pages/content";
@import "pages/product";
@import "./../design-tokens/css-variables.scss";


html{
    scroll-behavior: smooth;
}