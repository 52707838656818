
                  @import "@@/design-tokens/all.scss";
                
.button {
    white-space: nowrap;
    padding:0;
    text-decoration: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: ease 0.2s all;
    color:white;
    background-color: transparent;
  
    svg {
    margin-left: 8px;
    height:16px;
    margin-bottom:2px;
    }

    &__header {
        &:hover {
            opacity: 0.9;
        }
    }
    
    &__footer {
        &:hover {
            text-decoration: underline;
        }
    }
}