
                  @import "@@/design-tokens/all.scss";
                
.process_container {
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

.process_grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 260px);
  justify-content: center;
  grid-gap: 40px;
  @media #{$stena-medium-and-down} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media #{$stena-small-and-down} {
    grid-template-columns: 100%;
  }
}

.process_header {
  display: flex;
  justify-items: center;
  align-content: center;
}

.process-card {
  &__scroller-item {
    width: 260px;
  }
}

.header {
  max-width: 75%;
  h2 {
    max-width: 750px;
  }
}

.line {
  flex: 1;
  min-width: 40px;
  height: 0;
  border-bottom: 2px solid #3f4347;
  margin: auto;
}

.cards_wrapper {
  max-width: 1160px;
  display: flex;
}
